<template>
  <div>
    <template v-if="fieldEditable">
      <div class="edit-input_container">
        <el-form
          :model="formObj"
          ref="inputFieldForm"
          :rules="type == 'phone' ? {} : rules"
          :inline="true"
          size="normal"
        >
          <el-form-item prop="tempLabel" label="">
            <el-input
              v-model="formObj.tempLabel"
              placeholder=""
              size="mini"
              clearable
            ></el-input>
          </el-form-item>
        </el-form>
        <i @click="saveLabel" class="el-icon-check"></i>
        <i @click="closeEditMode" class="el-icon-close"></i>
      </div>
    </template>
    <template v-else>
      <span>{{ label }}</span>
      <i @click="fieldEditable = true" class="el-icon-edit"></i>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      fieldEditable: false,
      formObj: { tempLabel: "" },
      rules: {
        tempLabel: [
          {
            required: true,
            message: "Must input any Text",
            trigger: "change",
          },
          {
            min: 3,
            max: 25,
            message: "must input between 3 and 25 characters ",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.formObj.tempLabel = this.label;
  },
  methods: {
    closeEditMode() {
      this.fieldEditable = false;
      this.formObj.tempLabel = this.label;
    },
    async saveLabel() {
      const valid = await this.$refs["inputFieldForm"].validate();
      if (!valid) return;
      this.$emit("edit-field-saved", this.formObj.tempLabel);
      this.fieldEditable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
[class^="el-icon"] {
  margin: 0 0.5rem;
}
.el-icon-close {
  color: red !important;
}
.el-icon-check,
.el-icon-edit {
  color: #fccd13;
}
::v-deep .el-form-item {
  margin-bottom: 22px;
}
.edit-input_container {
  display: flex;
  align-items: center;
}
::v-deep .el-form-item__error {
  word-break: break-word;
  font-size: 9px;
}
</style>
