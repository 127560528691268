<template>
  <div>
    <div
      class="buttons-container u-display-flex u-justify-content--between u-algin-items--center u-bottom-margin--2x"
    >
      <el-upload
        class="u-right-margin--2x u-left-margin--2x"
        :on-change="uploadSheetFileChanged"
        :on-remove="removeSheetFile"
        action="#"
        :multiple="false"
        :auto-upload="false"
        :file-list="sheetFileList"
        accept=".xlsx, .csv"
      >
        <el-button slot="trigger" size="mini" type="primary">
          {{ $t("GlobalSelectFileButtonLabel") }}
        </el-button>
        <el-dropdown size="medium" @command="handleSheetImport">
          <el-button
            :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
            size="mini"
            icon="el-icon-top"
            type="info"
            plain
            :disabled="sheetFileList.length == 0"
            :title="sheetFileList.length == 0 ? 'true' : 'false'"
          >
            {{ $t("GlobalImportExcelButtonLabel") }}
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="create">Add</el-dropdown-item>
            <el-dropdown-item command="update">Update</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-upload>
      <div>
        <el-button
          size="mini"
          icon="el-icon-bottom"
          type="info"
          plain
          @click="handleSheetExport('update')"
        >
          {{ $t("GlobalExportFileButtonLabel") }}
        </el-button>
        <el-button
          size="mini"
          icon="el-icon-bottom"
          type="info"
          plain
          @click="handleSheetExport('create')"
        >
          {{ $t("GlobalTemplateDownloadButtonLabel") }}
        </el-button>
      </div>
      <a style="display: none" ref="sheet-download" target="_blank"></a>
    </div>
  </div>
</template>

<script>
import { providerAreaServices } from "@/services/providerArea.service";

export default {
  name: "AgentsSheetsHandler",
  props: {
    chosenFilters: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sheetFileList: [],
    };
  },
  computed: {
    distributerId() {
      return this.$route.params.id;
    },
  },
  methods: {
    uploadSheetFileChanged(changedData) {
      this.sheetFileList = [changedData];
    },
    removeSheetFile() {
      this.sheetFileList = [];
    },
    async handleSheetImport(chosenAction) {
      console.log("chosenFilters", this.chosenFilters);
      let toBeSentForm = new FormData();
      toBeSentForm.append("file", this.sheetFileList[0].raw);
      try {
        const response =
          await providerAreaServices.importProviderSectionAreaSheet(
            toBeSentForm,
            chosenAction
          );
        console.log("response", response);
        if (response && response.data) {
          const responseData = response.data;
          let defaultType = "application/json; charset=utf-8";
          let responseContentType = response.headers["content-type"];
          console.log("responseContentType", responseContentType);
          if (defaultType === responseContentType) {
            let decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(responseData)
            );
            let parsedResponse = JSON.parse(decodedString);
            if (parsedResponse.message) {
              this.$message({
                message: parsedResponse.message,
                type: "success",
                duration: 2000,
              });
            } else {
              this.$message({
                message: "Success",
                type: "success",
                duration: 2000,
              });
            }
          } else {
            let blob = new Blob([responseData], {
              type: response.headers["content-type"],
            });
            this.$refs["sheet-download"].setAttribute(
              "href",
              window.URL.createObjectURL(blob)
            );
            this.$refs["sheet-download"].setAttribute(
              "download",
              `uploadErrorSheet.xlsx`
            );
            this.$refs["sheet-download"].click();
            this.$message({
              message:
                "file uploaded successfully, please check the downloaded file",
              type: "success",
              duration: 4000,
            });
          }
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
      this.sheetFileList = [];
      this.$emit("dataUpdated");
    },
    async handleSheetExport(chosenAction) {
      const isCreate = chosenAction === "create";
      const entityId = isCreate ? null : this.distributerId;
      try {
        const response =
          await providerAreaServices.exportProviderSectionAreaSheet(
            chosenAction,
            entityId
          );
        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          this.$refs["sheet-download"].setAttribute(
            "href",
            window.URL.createObjectURL(blob)
          );
          let downloadedFileName = isCreate
            ? "ProviderSectionAreaTemplate.xlsx"
            : `ProviderSectionArea.xlsx`;
          this.$refs["sheet-download"].setAttribute(
            "download",
            downloadedFileName
          );
          this.$refs["sheet-download"].click();
          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        console.log("error", error);
        this.$message({
          message: "Error Occured",
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-container {
  background-color: white;
  padding: 15px 10px;
  border: 0.5px solid #dcdfe6;
  border-radius: 5px;
}
</style>
