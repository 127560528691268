<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="12">
        <el-tabs v-model="activeName" @tab-click="tabClicked" stretch>
          <el-tab-pane
            v-for="item in navigationItems"
            :label="item.tabLabel"
            :name="item.tabName"
            :key="item.tabLabel"
          ></el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <div v-if="activeName == 'sectionAreaManagment'">
      <ProviderSectionAreaManagement
        @dataUpdated="$emit('dataUpdated')"
        :providerAreasData="providerAreas"
      />
    </div>
    <div v-if="activeName == 'blacklisting'">
      <ProviderBlacklisting />
    </div>
    <div v-if="activeName == 'userProfiles'">
      <ProviderUserProfilesHandleing />
    </div>
  </div>
</template>

<script>
import ProviderSectionAreaManagement from "./ProviderSectionAreaManagement.vue";
import ProviderBlacklisting from "./ProviderBlacklistHandling.vue";
import ProviderUserProfilesHandleing from "./ProviderUserProfilesHandleing.vue";
// import RetailersWallets from "./RetailersWallets/RetailersWalletsComponent.vue";
export default {
  name: "ProviderProfileTabs",
  components: {
    ProviderSectionAreaManagement,
    ProviderBlacklisting,
    ProviderUserProfilesHandleing,
  },
  props: {
    providerAreas: {
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      activeName: "retailer",
      navigationItems: [
        {
          tabLabel: this.$t("ProviderProfilePageNavLabelSectionAreaMngmt"),
          tabName: "sectionAreaManagment",
        },
        {
          tabLabel: this.$t("ProviderProfilePageNavLabelBlacklist"),
          tabName: "blacklisting",
        },
        {
          tabLabel: "User Profiles",
          tabName: "userProfiles",
        },
      ],
    };
  },
  created() {
    this.chosenTabHandler();
  },
  methods: {
    chosenTabHandler() {
      const currentSessionTabs = sessionStorage.getItem("sessionTabs");
      if (currentSessionTabs) {
        let parseData = JSON.parse(currentSessionTabs);
        if (parseData.providerProfile) {
          this.activeName = parseData.providerProfile;
          return;
        } else {
          const defaultTabName = "sectionAreaManagment";
          this.activeName = defaultTabName;
          this.storeActiveTab(defaultTabName);
        }
      } else {
        const defaultTabName = "sectionAreaManagment";
        this.activeName = defaultTabName;
        this.storeActiveTab(defaultTabName);
      }
    },
    tabClicked(eventObject) {
      this.storeActiveTab(eventObject.name);
    },
    storeActiveTab(value) {
      let currentSessionObject = sessionStorage.getItem("sessionTabs");
      let finalValue;
      if (currentSessionObject) {
        let parseData = JSON.parse(currentSessionObject);
        finalValue = {
          ...parseData,
          providerProfile: value,
        };
      } else {
        finalValue = { providerProfile: value };
      }
      sessionStorage.setItem("sessionTabs", JSON.stringify(finalValue));
    },
  },
};
</script>

<style></style>
