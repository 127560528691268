<template>
  <el-dialog
    :title="$t('ProviderBlacklistDialogLabel')"
    :visible.sync="localVisible"
    @close="closeDialogAction"
    width="50%"
  >
    <div>
      <div class="u-bottom-margin--2x">
        {{ $t("ProviderBlacklistDialogFieldAddEntity") }}
      </div>
      <div class="u-bottom-margin--2x">
        <el-select
          v-model="searchText"
          filterable
          remote
          reserve-keyword
          :placeholder="$t('GlobalSearchLabel')"
          :remote-method="remoteMethod"
          :loading="loading"
          @change="entityClickedInDropdown"
        >
          <el-option v-for="item in fetchedUsers" :key="item.id" :value="item">
            {{ `${item.storeName} - ${item.name} - ${item.address}` }}
          </el-option>
        </el-select>
      </div>
    </div>
    <div>
      <div class="u-bottom-margin--2x">
        {{ $t("ProviderBlacklistDialogFieldBlacklist") }}
      </div>

      <el-table :data="chosenUsers" border stripe>
        <el-table-column
          prop="id"
          width="100px"
          :label="$t('ProviderBlacklistDialogTableEntityId')"
        />
        <el-table-column
          prop="storeName"
          :label="$t('ProviderBlacklistDialogTableColumnName')"
        >
          <template slot-scope="scope">
            <div class="u-text--right">
              <span>
                {{
                  `${scope.row.storeName} - ${scope.row.name} - ${scope.row.address}`
                }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100px" :label="``">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-close"
              circle
              style="color: red"
              @click="removeBlacklistRow(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelClicked">{{ $t("GlobalsCancelBtn") }}</el-button>
      <el-button
        type="success"
        @click="saveClicked"
        :disabled="chosenUsers.length === 0"
        >{{ $t("GlobalsSaveBtn") }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { entitiesServices } from "../../../services/entities.service";
export default {
  name: "ProviderBlacklistHandlingDialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      searchText: "",
      fetchedUsers: [],
      loading: false,
      chosenUsers: [],
      localVisible: false,
    };
  },
  methods: {
    cancelClicked() {
      this.closeDialogAction();
    },
    closeDialogAction() {
      this.chosenUsers = [];
      this.$emit("input", false);
    },
    async saveClicked() {
      try {
        const providerId = this.$store.getters.getCurrentProviderProfile.id;
        const blockedUsersIds = this.chosenUsers.map((item) => item.id);
        const apiObject = {
          entityId: providerId,
          blockedIds: blockedUsersIds,
        };
        const response = await entitiesServices.blackListUsers(apiObject);
        if (response && response.status == 200) {
          this.popupMessageWrapper(
            this.$t("ProviderBlacklistDialogSaveSuccessMessage"),
            "success",
            2500
          );
          setTimeout(() => {
            this.chosenUsers = [];
            this.$emit("blacklistSuccess");
          }, 2500);
        }
      } catch (error) {
        const errorMessage = this.errorMessageExtractor(error);
        this.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    async remoteMethod(query) {
      if (query !== "") {
        const filtersObject = {
          storeName: query.trim(),
          areaIds: this.openedProviderAreas,
        };
        try {
          this.loading = true;
          const res = await entitiesServices.fetchEntities(1, filtersObject);
          if (res) {
            this.fetchedUsers = res.data.data.entities;
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
        this.loading = false;
      }
    },
    removeBlacklistRow(clickedRow) {
      const newUsersArray = this.chosenUsers.filter(
        (user) => user.id != clickedRow.id
      );
      this.chosenUsers = newUsersArray;
    },
    entityClickedInDropdown(data) {
      try {
        const itemAlreadyInserted = this.chosenUsers.filter(
          (item) => item.id === data.id
        );
        if (!(itemAlreadyInserted && itemAlreadyInserted.length > 0)) {
          this.chosenUsers.push(data);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    },
    errorMessageExtractor(errorObject) {
      const errorMessageExistsError =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.error;
      const errorMessageExistsMessage =
        errorObject.response &&
        errorObject.response.data &&
        errorObject.response.data.message;
      let finalErrorMessage = this.$t(
        "RetailerWalletsPageWalletDefaultErrorMessage"
      );
      if (errorMessageExistsError) {
        finalErrorMessage = errorObject.response.data.error;
      }
      if (errorMessageExistsMessage) {
        finalErrorMessage = errorObject.response.data.message;
      }
      return finalErrorMessage;
    },
  },
  watch: {
    value(newValue) {
      this.localVisible = newValue;
    },
    localVisible(value, oldValue) {
      if (value !== oldValue) {
        this.$emit("input", value);
      }
      if (value === false) {
        this.chosenUsers = [];
        this.fetchedUsers = [];
        this.searchText = "";
      }
    },
  },
  computed: {
    openedProviderAreas() {
      const currentProviderAreas =
        this.$store.getters.getCurrentProviderProfile;
      let finalIdsArray = [];
      if (currentProviderAreas && currentProviderAreas.areas) {
        currentProviderAreas.areas.forEach((item) => {
          if (item.areaId) {
            finalIdsArray.push(item.areaId);
          }
        });
      }
      return finalIdsArray;
    },
  },
  created() {
    this.localVisible = this.value;
  },
};
</script>

<style></style>
