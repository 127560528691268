<template>
  <div class="u-top-margin--3x">
    <!-- <div class="u-bottom-margin--3x">
      <span>
        {{ $t("DistributerProfilePageSectionsManagementLabel") }}
      </span>
    </div> -->

    <!--  -------------------------------------------------- -->
    <!--  Sheets handling area -->
    <!--  -------------------------------------------------- -->
    <ProviderSectionAreaManagmentSheetsHandler
      @dataUpdated="$emit('dataUpdated')"
    />

    <el-row gutter="20">
      <el-col span="6">
        <el-form-item
          label="Industry"
          size="normal"
          prop="entity"
          label-width="20%"
          class="multipleSelectionCustomFormItem"
        >
          <el-select
            @change="filterParentRegionsAndSectionsByEntity"
            v-model="entity"
            filterable
            placeholder="Select an entity type"
          >
            <el-option
              v-for="item in lookups.entity_types.filter(
                (type) => type.activity === 'consumes'
              )"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          label-width="30%"
          label="Government"
          prop="parentRegionId"
          class="multipleSelectionCustomFormItem"
        >
          <el-select
            @change="filterAreasByParentRegion"
            v-model="parentRegionId"
            value-key=""
            placeholder="Please Select Government"
            clearable
            filterable
          >
            <el-option
              v-for="item in parentRegionsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <!-- <el-col :span="6">
        <el-form-item
          label-width="24%"
          label="Region"
          prop="regionId"
          class="multipleSelectionCustomFormItem"
        >
          <el-select
            @change="filterAreasByRegion"
            v-model="regionId"
            value-key=""
            placeholder="Please Select Region"
            clearable
            filterable
          >
            <el-option
              v-for="item in regionsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col> -->
      <el-col :span="6">
        <el-form-item
          label-width="20%"
          class="multipleSelectionCustomFormItem"
          :label="$t('DistributerProfilePageAreaLabel')"
          prop="requiredField"
        >
          <!-- v-if="lookups && lookups.areas" -->
          <el-select
            :disabled="!areaList"
            clearable
            v-model="tempAreaPriceObject.area"
            filterable
            multiple
            :placeholder="$t('DistributerProfilePageSelectAreaPlaceholder')"
          >
            <el-option
              v-for="(area, i) in areaList"
              :key="i"
              :label="area.name"
              :value="area.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col span="6">
        <el-form-item
          label-width="22%"
          class="multipleSelectionCustomFormItem"
          :label="$t('DistributerProfilePageLabelSections')"
          prop="requiredField"
        >
          <el-select
            :disabled="entity === '' ? true : false"
            clearable
            v-model="tempAreaPriceObject.sections"
            filterable
            multiple
            :placeholder="$t('DistributerProfilePagePlaceholderSections')"
          >
            <el-option
              v-for="(section, i) in sectionsList"
              :key="i"
              :label="section.key"
              :value="section.key"
            >
              <span style="float: left">{{ section.key }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                section.title
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="6">
        <el-form-item
          :label="$t('DistributerProfilePageMinimumOrderPriceLabel')"
          :label-width="formLabelWidth"
          :class="
            $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
          "
          prop="requiredField"
        >
          <el-input
            type="number"
            @input="() => this.validateIsPositive('minOrderAmount')"
            :placeholder="
              $t('DistributerProfilePageInputMinimumOrderPricePlaceholder')
            "
            v-model="tempAreaPriceObject.minOrderAmount"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          :label="$t('DistributerProfilePageMinimumItemsPerOrderLabel')"
          :label-width="`180px`"
          :class="
            $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
          "
          prop="requiredField"
        >
          <el-input
            type="number"
            @input="() => this.validateIsPositive('minNumberItems')"
            :placeholder="
              $t('DistributerProfilePageInputMinimumOrderItemPlaceholder')
            "
            v-model="tempAreaPriceObject.minNumberItems"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          :label="$t('DistributerProfilePageLabelIsActive')"
          :label-width="formLabelWidth"
        >
          <el-switch v-model="tempAreaPriceObject.isActive"> </el-switch>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item
          :label="$t('DistributerProfilePageRateLabel')"
          :label-width="formLabelWidth"
          :class="
            $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
          "
        >
          <el-rate allow-half v-model="tempAreaPriceObject.rating"> </el-rate>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row gutter="12">
      <el-col :span="6">
        <el-form-item
          :label="$t('DistributerProfilePageLabelPaymentMethod')"
          :label-width="formLabelWidth"
          prop="requiredField"
        >
          <el-select
            clearable
            v-model="tempAreaPriceObject.paymentMethod"
            filterable
            :placeholder="$t('DistributerProfilePagePlaceholderPayMethod')"
          >
            <el-option
              v-for="(singleMethod, i) in paymentMethods"
              :key="i"
              :label="singleMethod.label"
              :value="singleMethod.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button
          v-if="tempAreaPriceObject.area || multipleSelection.length > 0"
          :disabled="!tempAreaPriceObject.area"
          type="primary"
          @click="newAssignAreaToProvider"
        >
          {{ $t("DistributerProfilePageAddBtn") }}
        </el-button>
      </el-col>
    </el-row>

    <!--  -------------------------------------------------- -->
    <!--  Multiple Rows Selected for update Area -->
    <!--  -------------------------------------------------- -->
    <el-row v-if="multipleSelection.length > 0" type="flex-row">
      <el-col :span="5">
        <el-form-item
          :label="$t('DistributerProfilePageMinimumOrderPriceLabel')"
          :label-width="formLabelWidth"
        >
          <el-input
            type="number"
            :placeholder="
              $t('DistributerProfilePageInputMinimumOrderPricePlaceholder')
            "
            v-model="sectionsUpdatesObject.minOrderAmount"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-form-item
          :label="$t('DistributerProfilePageMinimumItemsPerOrderLabel')"
          :label-width="formLabelWidth"
        >
          <el-input
            type="number"
            :placeholder="
              $t('DistributerProfilePageInputMinimumOrderItemPlaceholder')
            "
            v-model="sectionsUpdatesObject.minNumberItems"
            autocomplete="off"
          />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item
          :label="$t('DistributerProfilePageRateLabel')"
          :label-width="`100px`"
        >
          <el-rate allow-half v-model="sectionsUpdatesObject.rating" />
        </el-form-item>
      </el-col>
      <el-col :span="3">
        <el-form-item
          :label="$t('DistributerProfilePageLabelIsActive')"
          :label-width="`100px`"
        >
          <el-switch allow-half v-model="sectionsUpdatesObject.isActive" />
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item
          :label="$t('DistributerProfilePageLabelPaymentMethod')"
          :label-width="formLabelWidth"
        >
          <el-select
            class="u-flex-shrink--0"
            v-model="sectionsUpdatesObject.paymentMethod"
            filterable
            :placeholder="$t('DistributerProfilePagePlaceholderPayMethod')"
          >
            <el-option
              v-for="(singleMethod, i) in paymentMethods"
              :key="i"
              :label="singleMethod.label"
              :value="singleMethod.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <el-button
          class="u-right-margin--2x"
          style="float: right"
          multipleSelection.length
          type="primary"
          @click="newMassEditAreas"
        >
          {{ $t("DistributerProfilePageMassEditBtn") }}
        </el-button>
      </el-col>
    </el-row>
    <div
      class="u-bottom-margin--1x u-top-margin--2x u-text--right"
      :class="{
        animate: isAreasUpdated,
      }"
    >
      <!-- <el-button
            type="primary"
            :disabled="!isAreasUpdated"
            @click="updateProviderAreas()"
          >
            Update Provider Areas
          </el-button> -->
    </div>
    <!--  -------------------------------------------------- -->
    <!--  Area Sections Table -->
    <!--  -------------------------------------------------- -->
    <div>
      <MetricFilterBox
        :defaultFilterData="metrics.states"
        v-model="metricsList"
        @clearFilters="clearFilters"
        @openMetric="saveMetircs"
        :clearBtnShowen="metricsList.length > 0"
      >
        <metricbox
          v-for="metric in metricsList"
          :key="metric.key"
          :label="$t(metric.label)"
          @close="closeMetric(metric, metric.filterKey)"
        >
          <template slot="filterBody">
            <component
              v-if="metric.key == 'isAreaActive'"
              :is="metric.compName"
              v-model="filters[metric.key]"
              :state="
                metric.displayedData ? metric.displayedData : filters.state
              "
              @change="filtersChanged(metric.key, $event)"
              @enterClicked="
                filterEnterClicked(metric.key, filters[metric.key])
              "
              :meta="metric.meta ? metric.meta : null"
            ></component>
            <component
              :is="metric.compName"
              v-model="filters[metric.key]"
              :meta="metric.meta ? metric.meta : null"
              @change="filtersChanged(metric.key, $event)"
            ></component>
          </template>
        </metricbox>
      </MetricFilterBox>
    </div>
    <div v-if="providerAreas.length">
      <el-table
        ref="areaTable"
        :data="providerAreas"
        style="width: 100%"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          :label="$t('DistributerProfilePageAreaNameColumnTitle')"
        >
          <template slot-scope="scope">
            {{ getAreaNameById(scope.row.areaId, scope.row.name) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="minOrderAmount"
          :label="$t('DistributerProfilePageMinimumOrderAmountColumnTitle')"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.editable">
              {{ scope.row.minOrderAmount }}
            </span>
            <el-input
              type="number"
              v-else
              :controls="false"
              v-model="scope.row.minOrderAmount"
              @change="isAreasUpdated = true"
              :min="1"
              label=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="minNumberItems"
          :label="$t('DistributerProfilePageMinimumItemsPerOrderColumnTitle')"
        >
          <template slot-scope="scope">
            <span v-if="!scope.row.editable">
              {{ scope.row.minNumberItems }}
            </span>
            <el-input
              type="number"
              v-else
              :controls="false"
              v-model="scope.row.minNumberItems"
              @change="isAreasUpdated = true"
              :min="1"
              label=""
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="rating"
          :label="$t('DistributerProfilePageRatingColumnTitle')"
        >
          <template slot-scope="scope">
            <div>
              <el-rate
                allow-half
                @change="isAreasUpdated = true"
                :disabled="!scope.row.editable"
                v-model="scope.row.rating"
              >
              </el-rate>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Section">
          <template slot-scope="scope">
            <div>
              {{ scope.row.sectionKey ? scope.row.sectionKey : "null" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isActive" label="isActive">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.isActive"
              :disabled="!scope.row.editable"
              :active-value="1"
              :inactive-value="0"
            />
          </template>
        </el-table-column>
        <el-table-column prop="paymentMethod" label="Payment Method">
          <template slot-scope="scope">
            <span v-if="!scope.row.editable">
              {{ scope.row.paymentMethod }}
            </span>
            <el-select
              class="u-flex-shrink--0"
              v-model="scope.row.paymentMethod"
              filterable
              v-else
              placeholder="Please select payment method"
            >
              <el-option
                v-for="(singleMethod, i) in paymentMethods"
                :key="i"
                :label="singleMethod.label"
                :value="singleMethod.value"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button
              type="success"
              :icon="scope.row.editable ? 'el-icon-check' : 'el-icon-edit'"
              title="Edit"
              plain
              :disabled="
                scope.row.minNumberItems === '' ||
                scope.row.minOrderAmount === ''
              "
              @click="EditRow(scope)"
            >
            </el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              plain
              @click="removeAreaFromProvider(scope.row)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { remove, isEmpty } from "lodash";
import { mapGetters } from "vuex";
import { providersService } from "../../../services/providers.service";
import MetricFilterBox from "@/components/transaction/MetricFilterBox";
import metricbox from "@/components/transaction/metricbox";
import { disProfileMetrics } from "@/components/transaction/metricboxComponents";
import _ from "lodash";
import ProviderSectionAreaManagmentSheetsHandler from "./ProviderSectionAreaManagmentSheetsHandler.vue";
import { regionsService } from "../../../services";
import extendedFunctions from "../../../utils/extendedFunctions";
export default {
  name: "ProviderSectionAreaManagement",
  components: {
    ...disProfileMetrics,
    MetricFilterBox,
    metricbox,
    ProviderSectionAreaManagmentSheetsHandler,
  },
  data() {
    return {
      filters: {},
      metricsList: [],
      metrics: {
        states: [
          {
            key: "isAreaActive",
            label: "Area Status",
            compName: "dynamicTypeMetric",
            displayedData: [
              {
                value: "Active",
              },
              {
                value: "Inactive",
              },
            ],
          },
        ],
      },
      regionId: "",
      parentRegionId: "",
      entity: "",
      multipleSelection: [],
      formLabelWidth: "160px",
      tempAreaPriceObject: {
        minNumberItems: 1,
        isActive: true,
        // fatura: false,
        // credit: false,
        // direct: true,
        // faturaCredit: false,
      },
      paymentMethods: [
        { label: "Cash", value: "cash" },
        { label: "Both", value: "both" },
      ],
      sectionsUpdatesObject: {
        isActive: false,
      },
      isAreasUpdated: false,
      providerAreas: [],
      areaList: undefined,
      areas: undefined,
      parentRegionsList: undefined,
      regionsList: undefined,
      sectionsList: undefined,
    };
  },
  props: {
    providerAreasData: {
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    distributerId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.providerAreas = JSON.parse(JSON.stringify(this.providerAreasData));
  },
  watch: {
    providerAreasData(newValue) {
      console.log("in watch", newValue);
      this.providerAreas = JSON.parse(JSON.stringify(newValue));
      console.log("this.providerAreas", this.providerAreas);
      // this.providerAreas = [];
    },
  },
  methods: {
    async getProfile() {
      this.$loading();
      try {
        let providerProfileData = await providersService.getProviderProfile(
          this.distributerId,
          this.filters
        );
        console.log(
          "🚀 ~ getProfile ~ providerProfileData:",
          providerProfileData
        );
        if (!providerProfileData.meta) {
          providerProfileData.meta = {};
        }
        if (providerProfileData.meta) {
          const currentMeta = providerProfileData.meta;
          let currentProfileImage = currentMeta.logo
            ? currentMeta.logo
            : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
          this.profileImageUrl = currentProfileImage;
        }
        this.providerAreas = providerProfileData.areas.map((item) => {
          return { ...item, editable: false };
        });
        console.log(providerProfileData);

        this.providerProfile = { ...providerProfileData };
        this.$store.commit("setCurrentProviderProfile", providerProfileData);
        this.$loading().close();
        return providerProfileData;
      } catch (error) {
        console.log("error :>> ", error);
        this.$loading().close();
      }
    },
    filtersChanged(key, value) {
      if (!(typeof value === "number") && isEmpty(value)) {
        this.filters[key] = undefined;
      }
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.disProfile = {
        filters: { ...this.filters },
      };
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.reloadPage();
    },
    clearFilters() {
      this.filters = {};
      let sessionFilter = JSON.parse(sessionStorage.getItem("filter"));
      sessionFilter.disProfile = this.filters;
      sessionStorage.setItem("filter", JSON.stringify(sessionFilter));
      this.metricsList = [];
      localStorage.setItem("metricsDisProfile", []);
      this.reloadPage();
    },
    saveMetircs() {
      localStorage.setItem(
        "metricsDisProfile",
        JSON.stringify(this.metricsList)
      );
    },
    closeMetric(metric) {
      remove(this.metricsList, (item) => {
        return item.key == metric.key;
      });
      if (this.filters[metric.key]) this.filtersChanged(metric.key, undefined);
      localStorage.setItem(
        "metricsDisProfile",
        JSON.stringify(this.metricsList)
      );
      this.metricsList = [...this.metricsList];
    },
    filterEnterClicked(key, value) {
      this.filtersChanged(key, value);
    },
    reloadPage() {
      this.getProfile();
    },
    // this.getPromocodes(this.currentPage);

    // async filterRegionsByParent() {
    //   try {
    //     const regions = await regionsService.getRegionsByEntity(
    //       this.entity,
    //       this.parentRegionId
    //     );
    //     console.log("🚀 ~ filterRegionsByParent ~ regions:", regions)
    //     this.regionsList = regions.data.data.map((a) => ({
    //       id: a.id,
    //       name: a.region,
    //     }));
    //   } catch (err) {
    //     this.$notify({
    //       title: this.$t("GlobalsErrorTitle"),
    //       message: extendedFunctions.errorMessageExtractor(err),
    //       type: "error",
    //     });
    //   }
    // },
    async filterParentRegionsAndSectionsByEntity() {
      try {
        const sections = await providersService.getSectionsByEntity(
          this.entity
        );
        const parentRegions = await regionsService.getRegionsByEntity(
          this.entity
        );
        this.parentRegionsList = parentRegions.data.data.map((a) => ({
          id: a.id,
          name: a.region,
        }));
        this.sectionsList = sections.data.data;
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
    async filterAreasByParentRegion() {
      try {
        const {
          data: {
            data: { areas },
          },
        } = await regionsService.getAreasLookup({
          filter: { regionId: this.parentRegionId },
        });
        if (this.parentRegionId === "") {
          this.areaList = undefined;
          delete this.tempAreaPriceObject.area;
        }
        if (this.parentRegionId !== "") {
          this.areaList = areas.map((a) => ({
            id: a.key,
            name: a.value,
          }));
          this.areas = areas.map((a) => a.key);
        }
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
    validateIsPositive(fieldKey) {
      if (this.tempAreaPriceObject[fieldKey] < 0)
        this.tempAreaPriceObject[fieldKey] =
          this.tempAreaPriceObject[fieldKey] * -1;
    },
    async newAssignAreaToProvider() {
      const dataIsValid = this.validateCreateData();
      if (dataIsValid == false) return;
      const newInsertionsData = JSON.parse(
        JSON.stringify(this.tempAreaPriceObject)
      );
      const chosenAreas = newInsertionsData.area;
      const chosenSections = newInsertionsData.sections;
      const commonData = {
        isActive: newInsertionsData.isActive,
        minNumberItems: newInsertionsData.minNumberItems,
        paymentMethod: newInsertionsData.paymentMethod,
        price: newInsertionsData.price,
        rating: newInsertionsData.rating,
        minOrderAmount: newInsertionsData.minOrderAmount,
      };
      let toBeSentData = [];
      chosenAreas.forEach((singleArea) => {
        chosenSections.forEach((singleSection) => {
          let tempObj = {
            ...commonData,
            areaId: singleArea,
            sectionKey: singleSection,
          };
          toBeSentData.push(JSON.parse(JSON.stringify(tempObj)));
        });
      });
      const providerId = this.distributerId;
      this.$loading();
      try {
        const response = await providersService.newAssignProvidersToSections(
          providerId,
          toBeSentData
        );
        if (response) {
          this.$loading().close();
          this.tempAreaPriceObject = {
            minNumberItems: 1,
            isActive: true,
            area: [],
          };
          this.entity = "";
          this.regionId = "";
          this.$emit("dataUpdated");
        }
      } catch (error) {
        console.log("error", error);
        this.$loading().close();
      }
    },
    async newMassEditAreas() {
      const constructedData = this.constructUpdateData();
      this.$confirm(constructedData.message, "Warning", {
        confirmButtonText: this.$t("GlobalOkBtn"),
        dangerouslyUseHTMLString: true,
        cancelButtonText: this.$t("GlobalCancelBtn"),
      })
        .then(async () => {
          const commonData = constructedData.dataToBeSent;
          let toBeSentData = [];
          this.multipleSelection.forEach((singleSelection) => {
            let tempObj = {
              ...commonData,
              areaId: singleSelection.areaId,
              sectionKey: singleSelection.sectionKey,
            };
            toBeSentData.push(JSON.parse(JSON.stringify(tempObj)));
          });
          const providerId = this.distributerId;
          this.$loading();
          try {
            const response =
              await providersService.newAssignProvidersToSections(
                providerId,
                toBeSentData
              );
            if (response) {
              this.$loading().close();
              this.resetSectionsUpdateObject();
              this.$emit("dataUpdated");
            }
          } catch (error) {
            console.log("error", error);
            this.$loading().close();
          }
        })
        .catch(() => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.resetSectionsUpdateObject();
    },
    getAreaNameById(areaId, areaName) {
      if (!this.lookups) return;
      let area = _.find(this.lookups.areas, { key: areaId });
      return area && area.value ? area.value : areaName ?? "Unknown";
    },
    async EditRow(scope) {
      scope.row.editable = !scope.row.editable;
      if (!scope.row.editable) {
        const chosenRow = JSON.parse(JSON.stringify(scope.row));
        let toBeSentData = [
          {
            areaId: chosenRow.areaId,
            sectionKey: chosenRow.sectionKey,
            minOrderAmount: chosenRow.minOrderAmount,
            minNumberItems: chosenRow.minNumberItems,
            rating: chosenRow.rating,
            isActive: chosenRow.isActive,
            paymentMethod: chosenRow.paymentMethod,
          },
        ];
        const providerId = this.distributerId;
        this.$loading();
        try {
          const response = await providersService.newAssignProvidersToSections(
            providerId,
            toBeSentData
          );
          if (response) {
            this.$loading().close();
            this.$emit("dataUpdated");
          }
        } catch (error) {
          console.log("error", error);
          this.$loading().close();
        }
      }
    },
    async removeAreaFromProvider(clickedSection) {
      this.$confirm(
        `${this.$t("DistributerProfilePageDeleteAreaWarningBoxMessage")}`,
        `${this.$t("DistributerProfilePageDeleteAreaWarningBoxTitle")}`,
        {
          confirmButtonText: this.$t(
            "DistributerProfilePageDeleteAreaWarningBoxOkBtn"
          ),
          cancelButtonText: this.$t(
            "DistributerProfilePageDeleteAreaWarningBoxCancelBtn"
          ),
        }
      )
        .then(async () => {
          const entityId = this.distributerId;
          const areaId = clickedSection.areaId;
          const sectionKey = clickedSection.sectionKey;
          try {
            this.$loading();
            const response = await providersService.deleteProviderSection(
              entityId,
              areaId,
              sectionKey
            );
            if (response) {
              this.$loading().close();
              this.$emit("dataUpdated");
            }
          } catch (error) {
            this.$loading().close();
            this.$notify({
              title: "Error Occured",
              message: error.response.data.message,
              type: "error",
            });
          }
        })
        .catch(() => {});
    },
    validateCreateData() {
      try {
        const newInsertionsData = JSON.parse(
          JSON.stringify(this.tempAreaPriceObject)
        );
        const chosenAreas = newInsertionsData.area;
        const chosenSections = newInsertionsData.sections;

        let returnedFlag = true;
        let errorMessage = "";

        if (chosenSections.length == 0) {
          errorMessage = this.$t(
            "DistributerProfilePageValidationErrorMessageSections"
          );
          returnedFlag = false;
        }
        if (!("paymentMethod" in newInsertionsData)) {
          errorMessage = this.$t(
            "DistributerProfilePageValidationErrorMessagePayMethod"
          );
          returnedFlag = false;
        }
        if (!("minOrderAmount" in newInsertionsData)) {
          errorMessage = this.$t(
            "DistributerProfilePageValidationErrorMessageMinOrderAmount"
          );
          returnedFlag = false;
        }
        if (chosenAreas.length == 0) {
          errorMessage = this.$t(
            "DistributerProfilePageValidationErrorMessageAreas"
          );
          returnedFlag = false;
        }

        if (!returnedFlag)
          this.popupMessageWrapper(errorMessage, "error", 2000);

        return returnedFlag;
      } catch (error) {
        console.log("error :>> ", error);
        this.popupMessageWrapper(
          this.$t("DistributerProfilePageValidationErrorMessageDefaultError"),
          "error",
          2000
        );
        return false;
      }
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    constructUpdateData() {
      let message = `${this.$t(
        "DistributerProfilePageMassEditConfirmBoxTitle"
      )}`;
      let dataToBeSent = {};
      const updateObj = JSON.parse(JSON.stringify(this.sectionsUpdatesObject));
      if (updateObj.minOrderAmount) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxMinimumOrderPriceLabel"
        )} </strong> ${this.sectionsUpdatesObject.minOrderAmount}`;
        dataToBeSent.minOrderAmount = updateObj.minOrderAmount;
      }
      if (updateObj.minNumberItems) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxMinimumItemsPerOrderLabel"
        )} </strong> ${this.sectionsUpdatesObject.minNumberItems}`;
        dataToBeSent.minNumberItems = updateObj.minNumberItems;
      }
      if (updateObj.rating && updateObj.rating > 0) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxLabelRate"
        )}</strong> ${this.sectionsUpdatesObject.rating}`;
        dataToBeSent.rating = updateObj.rating;
      }
      if ("isActive" in updateObj) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxLabelIsActive"
        )}</strong> ${
          this.sectionsUpdatesObject.isActive == 1 ? "true" : "false"
        }`;
        dataToBeSent.isActive = updateObj.isActive;
      }
      if (updateObj.paymentMethod) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxLabelPayMethod"
        )}</strong> ${this.sectionsUpdatesObject.paymentMethod}`;
        dataToBeSent.paymentMethod = updateObj.paymentMethod;
      }
      return { message, dataToBeSent };
    },
    resetSectionsUpdateObject() {
      this.sectionsUpdatesObject = {
        isActive: false,
      };
    },
  },
};
</script>

<style lang="scss">
.multipleSelectionCustomFormItem {
  display: flex;
  justify-content: flex-start;
  .el-form-item__content {
    width: 100%;
    margin: unset !important;
    .el-select {
      width: 100%;
    }
  }
}
</style>
>
