<template>
  <div>
    <!-- ------------------------------------------------------------ -->
    <!-- User Profiles  Area -->
    <!-- ------------------------------------------------------------ -->
    <el-card :body-style="{ padding: '50px' }">
      <el-radio-group @change="getDisplayedData" v-model="displayType">
        <el-radio-button label="users">{{
          $t("RetailersDetailsPageTabUsers")
        }}</el-radio-button>
        <el-radio-button label="deactivatedUsers"
          >Deactivated Users</el-radio-button
        >
        <el-radio-button label="blacklist">{{
          $t("RetailersDetailsPageTabBlacklist")
        }}</el-radio-button>
      </el-radio-group>
      <template v-if="displayType">
        <div
          v-if="displayType === 'users'"
          class="u-top-margin--4x u-bottom-margin--4x actions-container"
        >
          <el-input
            v-model="secondPhone"
            placeholder="Filter by second phone "
            size="normal"
            clearable
          ></el-input>
          <el-button
            type="primary"
            size="default"
            @click="showProfileDialog = true"
            >Add New User to this Entity</el-button
          >

          <user-profile-dialog
            :isDialogVisible="showProfileDialog"
            @user-saved="addNewProfile"
            @dialog-closed="showProfileDialog = false"
          ></user-profile-dialog>
        </div>
        <styled-table :data="displayedData">
          <template v-for="col in displayedColumn">
            <el-table-column
              v-if="col.EditField"
              :prop="col.prop"
              :label="$t(col.label)"
              width="220"
              :key="col.prop"
            >
              <template slot-scope="scope">
                <input-edit-field
                  v-if="col.prop == 'userName'"
                  :label="scope.row.userName"
                  @edit-field-saved="updateRetailerUserName($event, scope.row)"
                ></input-edit-field>
                <input-edit-field
                  v-else
                  :label="scope.row.secondPhone"
                  type="phone"
                  @edit-field-saved="
                    updateRetailerSecondPhone($event, scope.row)
                  "
                ></input-edit-field>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="!col.parseActiveToBool"
              :prop="col.prop"
              :label="$t(col.label)"
              :width="col.width ? col.width : ''"
              :key="col.prop"
            >
            </el-table-column>
            <el-table-column
              v-else-if="col.showOperations"
              :prop="col.prop"
              :label="$t(col.label)"
              width="220"
              :key="col.prop"
            >
              <template slot-scope="scope">
                <div class="user-actions">
                  <el-button
                    @click="updateRetailerActivation(true, scope.row.id)"
                    >Reactivate</el-button
                  >
                  <el-button @click="reassignProfileDialog = true"
                    >Reassign</el-button
                  >
                </div>
                <el-dialog
                  title="Reassign User to new Entity"
                  :visible.sync="reassignProfileDialog"
                  width="30%"
                  @close="newEntitiyID = null"
                >
                  <el-select
                    v-model="newEntitiyID"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Select new entity"
                    :remote-method="remoteMethod"
                  >
                    <el-option
                      v-for="item in entitiesList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.storeName"
                    >
                      {{ `${item.storeName} - ${item.name} - ${item.address}` }}
                    </el-option>
                  </el-select>

                  <span slot="footer">
                    <el-button @click="reassignProfileDialog = false"
                      >Cancel</el-button
                    >
                    <el-button
                      type="primary"
                      :disabled="newEntitiyID === null"
                      @click="addNewProfile(scope.row, true)"
                      >OK</el-button
                    >
                  </span>
                </el-dialog>
              </template>
            </el-table-column>

            <el-table-column
              v-else
              :prop="col.prop"
              :label="$t(col.label)"
              :key="col.prop"
            >
              <template slot-scope="scope">
                <div>
                  <el-button
                    type="danger"
                    size="small"
                    @click="updateRetailerActivation(false, scope.row.userId)"
                    >Deactivate</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </template>
        </styled-table>
      </template>
      <div v-else class="msg-page u-font--super">
        {{ $t("RetailersDetailsPageNoUserTab") }}
      </div>
    </el-card>
  </div>
</template>

<script>
import styledTable from "../../../components/dataContainers/styledTable.vue";
import InputEditField from "../../../components/InputEditField.vue";
import UserProfileDialog from "../../EntitiesScreens/RetailersManagement/UserProfileDialog.vue";
import { providersService, retailersService } from "../../../services";
import extendedFunctions from "../../../utils/extendedFunctions";
import { entitiesServices } from "../../../services/entities.service";
export default {
  components: { styledTable, InputEditField, UserProfileDialog },
  data() {
    return {
      displayType: "users",
      blackListUsers: [],
      ProviderUsers: [],
      showProfileDialog: false,
      reassignProfileDialog: false,
      newEntitiyID: null,
      entitiesList: [],
      secondPhone: null,
      blackListColumn: [
        { prop: "entityId", label: "RetailersDetailsPageTableColumnEntityId" },
        { prop: "name", label: "RetailersDetailsPageTableColumnName" },
        {
          prop: "created_at",
          label: "RetailersDetailsPageTableColumnCreatedAt",
        },
      ],
      ProviderUsersColumn: [
        { prop: "userId", label: "RetailersDetailsPageTableColumnId" },
        {
          prop: "userName",
          label: "RetailersDetailsPageTableColumnName",
          EditField: true,
        },
        { prop: "userPhone", label: "RetailersDetailsPageTableColumnPhone" },
        { prop: "secondPhone", label: "second phone", EditField: true },

        {
          prop: "userCreationDate",
          label: "RetailersDetailsPageTableColumnCreatedAt",
        },
        {
          prop: "userLastSeenDate",
          label: "RetailersDetailsPageTableColumnLastSeenAt",
        },
        {
          prop: "userLastTransactionDate",
          label: "RetailersDetailsPageTableColumnLastTransactionAt",
        },
        {
          prop: "userActiveFlag",
          label: "RetailersDetailsPageTableColumnActive",
          parseActiveToBool: true,
        },
      ],
      providerDeactivatedUsersColumn: [
        { prop: "id", label: "RetailersDetailsPageTableColumnId" },
        {
          prop: "name",
          label: "RetailersDetailsPageTableColumnName",
        },
        { prop: "phone", label: "RetailersDetailsPageTableColumnPhone" },

        {
          prop: "created_at",
          label: "RetailersDetailsPageTableColumnCreatedAt",
        },
        {
          prop: "suspended_at",
          label: "Deactivated at",
        },

        {
          prop: "deactivationComment",
          label: "Deactivation comment",
        },
        {
          prop: "Operations",
          label: "Operations",
          parseActiveToBool: true,
          showOperations: true,
        },
      ],
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    displayedData() {
      return this.displayType == "blacklist"
        ? this.blackListUsers
        : this.ProviderUsers.filter((user) =>
            this.secondPhone && this.displayType != "deactivatedUsers"
              ? user.secondPhone === this.secondPhone
              : true
          );
    },
    displayedColumn() {
      if (this.displayType == "blacklist") return this.blackListColumn;
      else if (this.displayType == "users") return this.ProviderUsersColumn;
      return this.providerDeactivatedUsersColumn;
    },
  },
  mounted() {
    this.getProviderUsers();
  },
  methods: {
    getDisplayedData() {
      if (this.displayType == "blacklist") return this.getBlacklist();
      else if (this.displayType == "deactivatedUsers")
        return this.getRetailerDeactivatedUsers();
      this.getProviderUsers();
    },
    async getBlacklist() {
      try {
        let blacklistRes = await retailersService.getBlacklistUsers(this.id);
        this.blackListUsers = blacklistRes.blacklistUsers;
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async getProviderUsers() {
      try {
        let res = await providersService.getProviderUsers(this.id);
        this.ProviderUsers = res.retailerUsers.filter(
          (user) => user.userActiveFlag
        );
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async getRetailerDeactivatedUsers() {
      try {
        let res = await retailersService.getRetailersDeactivatedUsers(this.id);
        this.ProviderUsers = res.data.data.result;
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async updateRetailerUserName(name, row) {
      try {
        await retailersService.updateRetailersUsers(this.id, row.userId, {
          userName: name,
        });
        let userIndex = this.ProviderUsers.findIndex(
          (r) => r.userId == row.userId
        );
        this.$set(this.ProviderUsers[userIndex], "userName", name);
        this.$notify({
          title: "Success",
          message: "User Name Updated successfully",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async updateRetailerSecondPhone(phone, row) {
      try {
        await retailersService.updateRetailersUsers(this.id, row.userId, {
          secondPhone: phone,
        });
        let userIndex = this.ProviderUsers.findIndex(
          (r) => r.userId == row.userId
        );
        this.$set(this.ProviderUsers[userIndex], "secondPhone", phone);
        this.$notify({
          title: "Success",
          message: "second phone Updated successfully",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async updateRetailerActivation(activationValue, userId) {
      const activationObject = { suspended_at: null };
      if (activationValue == false) {
        if (this.ProviderUsers.length === 1) {
          const shouldDeactivate = await this.$confirm(
            "Deactivating this user will deactivate the whole retailer entity.",
            {
              confirmButtonText: "confirm",
              cancelButtonText: "cancel",
              type: "warning",
            }
          );
          if (!shouldDeactivate) return;
        }

        const deactivationReason = await this.$prompt(
          "Please Enter Deactivation Reason",
          "Deactivate User",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            inputPattern: /[a-zA-Z0-9]/,
            inputErrorMessage: "Must enter a Reason",
          }
        );
        const deactivationDate = new Date().toString();
        activationObject.suspended_at = deactivationDate;
        activationObject.deactivationComment = deactivationReason.value;
      } else {
        const reactiveConfirmed = await this.$confirm(
          "Are you sure you want to reactive the User",
          {
            confirmButtonText: "confirm",
            cancelButtonText: "cancel",
            type: "warning",
          }
        );
        if (!reactiveConfirmed) return;
      }
      try {
        await retailersService.updateRetailersUsers(this.id, userId, {
          ...activationObject,
        });
        if (activationValue == false) this.getProviderUsers();
        else this.getRetailerDeactivatedUsers();
        this.$notify({
          title: "Success",
          message: "User Updated successfully",
          type: "success",
        });
      } catch (error) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(error),
          type: "error",
        });
      }
    },
    async addNewProfile(profileData, fromDeactivatedList = false) {
      const isFromReassign = this.newEntitiyID ? true : false;
      const sendObj = {
        ...profileData,
      };
      const entityId = isFromReassign ? this.newEntitiyID : this.id;
      if (isFromReassign) {
        const isConfirmed = await this.$confirm(
          "Are you sure you want to reassign the User to then entity",
          {
            confirmButtonText: "confirm",
            cancelButtonText: "cancel",
            type: "warning",
          }
        );
        if (!isConfirmed) return;
      }
      try {
        await entitiesServices.addNewUserProfileToEntity(entityId, sendObj);
        this.showProfileDialog = false;
        if (!fromDeactivatedList) {
          this.getProviderUsers();
          this.$notify({
            title: "Success",
            message: "Provider User Created successfully",
            type: "success",
          });
        } else {
          this.reassignProfileDialog = false;
          this.getRetailerDeactivatedUsers();
          this.$notify({
            title: "Success",
            message: "Provider User Reassigned successfully",
            type: "success",
          });
        }
      } catch (err) {
        this.$notify({
          title: this.$t("GlobalsErrorTitle"),
          message: extendedFunctions.errorMessageExtractor(err),
          type: "error",
        });
      }
    },
    async remoteMethod(query) {
      if (query !== "") {
        const filtersObject = {
          storeName: query.trim(),
          areaIds: this.openedProviderAreas,
        };
        try {
          const res = await entitiesServices.fetchEntities(1, filtersObject);
          if (res) {
            this.entitiesList = res.data.data.entities;
          }
        } catch (error) {
          this.$notify({
            title: this.$t("GlobalsErrorTitle"),
            message: extendedFunctions.errorMessageExtractor(error),
            type: "error",
          });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.actions-container {
  display: flex;
  justify-content: space-between;
  .el-input {
    width: auto;
  }
}
.user-actions {
  display: flex;
  justify-content: space-around;
}
</style>
