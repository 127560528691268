<template>
  <div>
    <el-page-header class="u-bottom-margin--2x" :title="$t('GlobalsBackBtn')"
      :content="$t('DistributerProfilePageTitle', [providerProfile.name])" @back="goBack">
    </el-page-header>
    <!-- <el-radio-group v-model="labelPosition">
      <el-radio-button
        v-for="(section, index) in profileSections"
        :key="index"
        :label="section"
        >{{ section }}</el-radio-button
      >
    </el-radio-group> -->
    <!--  -------------------------------------------------- -->
    <!-- Areas-Seaction -->
    <!--  -------------------------------------------------- -->
    <div v-if="!EditMode" class="distributer-profile__top-buttons-holder">
      <el-button class="u-bottom-margin--2x" size="mini" type="primary" @click="showWorkingHoursDialog = true">{{
      $t("ProviderWorkingHoursBtnLabel") }}
      </el-button>
      <el-button icon="el-icon-edit" class="u-bottom-margin--2x" size="mini" type="primary" @click="EditMode = true">{{
      $t("DistributerProfilePageEditBtn") }}
      </el-button>
    </div>
    <div v-else class="u-bottom-margin--2x distributer-profile__top-buttons-holder">
      <el-button size="mini" type="primary" @click="showWorkingHoursDialog = true">{{ $t("ProviderWorkingHoursBtnLabel")
        }}
      </el-button>
      <el-button icon="el-icon-close" size="mini" type="danger" @click="cancelEdit">
        {{ $t("GlobalCancelBtn") }}
      </el-button>
      <el-button type="primary" size="mini" @click="EditDistributer">
        {{ $t("DistributerProfilePageSaveBtn") }}
      </el-button>
    </div>

    <div v-if="labelPosition == 'Areas'">
      <el-form :model="providerProfile" :rules="editDistributerRules" ref="editDistributerForm">
        <el-row :gutter="20">
          <el-col :span="24" :offset="0">
            <el-row class="u-bottom-margin--2x">
              <el-col :span="12">
                <div class="distributer-profile__image-holder" :style="{
      'background-image': 'url(' + profileImageUrl + ')',
    }"></div>
                <div class="distributer-profile__edit-image-button-holder">
                  <el-upload v-if="EditMode" :on-change="handleChangeImage" :file-list="imageFileList" action="#"
                    :show-file-list="false" :multiple="false" :auto-upload="false" list-type="picture"
                    accept=".jpg, .jpeg, .png">
                    <el-button slot="trigger" type="primary" circle icon="el-icon-camera" size="medium">
                    </el-button>
                    <el-button @click="handleRemoveImage" v-if="imageFileList.length > 0" circle type="danger"
                      icon="el-icon-delete" size="medium" :class="$store.state.isRTL
        ? 'u-right-margin--1x'
        : 'u-left-margin--1x'
      ">
                    </el-button>
                  </el-upload>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" :offset="0">
                <el-form-item :label="$t('DistributerProfilePageStoreNameLabel')" :label-width="formLabelWidth">
                  <el-input v-model="providerProfile.name" :disabled="!EditMode">
                  </el-input>
                </el-form-item>
              </el-col>

              <!-- <el-col :span="12" :offset="0">
                <el-form-item
                  :label="$t('DistributerProfilePageUserNameLabel')"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="providerProfile.user.name"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col> -->
              <el-col :span="12" :offset="0">
                <el-form-item :label="$t('DistributerProfilePageProviderTypeLabel')" :label-width="formLabelWidth">
                  <el-select style="width: 100%" v-model="providerProfile.providerType" :placeholder="$t('DistributerProfilePageSelectProviderTypePlaceholder')
      " size="normal" :disabled="!EditMode">
                    <el-option v-for="p in providesActivityTypeArray" :key="p.id" :label="p.name" :value="p.name">
                      <!-- <span style="float: left">{{ p.label }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{
                        p.tooltip
                      }}</span> -->
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form-item :label="$t('DistributerProfilePageAddress')" :label-width="formLabelWidth">
                  <el-input v-model=" providerProfile.user.address" :disabled="!EditMode">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form-item :label="$t('DistributerProfilePageLabelProductCategory')" :label-width="formLabelWidth">
                  <el-input v-model="providerProfile.productsCategory" :disabled="!EditMode"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-form-item :label="$t('DistributerProfilePageLabelLandline')" :label-width="formLabelWidth">
                  <el-input v-model="providerProfile.user.landline" :disabled="!EditMode"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" :offset="0">
                <el-row>
                  <el-col :span="24">
                    <el-form-item :label="$t('DistributerProfilePageActiveLabel')" :label-width="formLabelWidth">
                      <el-switch :disabled="!EditMode" v-model="providerProfile.active" :active-value="1"
                        :inactive-value="0" /> </el-form-item></el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="Type ID" :label-width="formLabelWidth" prop="typeId">
              <el-select v-model="providerProfile.typeId" :disabled="!EditMode" @change="typeIdChanged">
                <div>
                  <el-option key="nationalId" label="National ID" value="nationalId"></el-option>
                  <el-option key="taxId" label="Tax ID" value="taxId"></el-option>
                </div>
              </el-select>
            </el-form-item>
          </el-col>
          <template v-if="providerProfile.typeId === 'nationalId'">
            <el-col :span="8">
              <el-form-item label="National Name" :label-width="formLabelWidth" prop="nationalName">
                <el-input :disabled="!EditMode" v-model="providerProfile.nationalName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="National Id" :label-width="formLabelWidth" prop="nationalId">
                <el-input :disabled="!EditMode" v-model="providerProfile.nationalId"
                  @input="limitInputToMaxDigits(14, 'nationalId')" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </template>
          <template v-if="providerProfile.typeId === 'taxId'">
            <el-col :span="8">
              <el-form-item label="Tax Name" :label-width="formLabelWidth" prop="taxName">
                <el-input :disabled="!EditMode" v-model="providerProfile.taxName" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Tax Id" :label-width="formLabelWidth" prop="taxId">
                <el-input :disabled="!EditMode" v-model="providerProfile.taxId"
                  @input="limitInputToMaxDigits(9, 'taxId')" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
          </template>
        </el-row>

        <el-form-item :label="$t('DistributerProfilePageNotesLabel')" :label-width="formLabelWidth">
          <el-input v-model="providerProfile.user.notes" :disabled="!EditMode"></el-input>
        </el-form-item>

        <el-form-item label="Description" :label-width="formLabelWidth">
          <el-input v-model="providerProfile.oneLineDescription" :disabled="!EditMode"></el-input>
        </el-form-item>

        <div class="danger-zone">
          <el-form-item :label="`Toggle Offline (Visibility to retailers)`" :label-width="formLabelWidth">
            <el-switch :disabled="!EditMode" v-model="providerProfile.is_offline" :active-value="1" :inactive-value="0"
              @change="offlineToggleChanged">
            </el-switch>
          </el-form-item>
          <div v-if="providerProfile.is_offline">
            <div style="display: flex">
              <el-form-item :label="`Start Date`" prop="startDate" label-width="160px" style="flex-basis: 50%">
                <el-date-picker v-model="providerProfile.startDate" type="datetime" placeholder="Select date and time"
                  style="width: 100%" :disabled="true">
                </el-date-picker>
              </el-form-item>

              <el-form-item label-width="160px" :label="`End Date`" prop="endDate" style="flex-basis: 50%">
                <el-date-picker v-model="providerProfile.endDate" type="datetime" placeholder="Select date and time"
                  style="width: 100%" :disabled="!EditMode">
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
        </div>

        <el-divider></el-divider>

        <ProviderProfileTabs @dataUpdated="getProfile" :providerAreas="providerAreas" />
        <!-- <div class="u-bottom-margin--3x">
          <span>
            {{ $t("DistributerProfilePageSectionsManagementLabel") }}
          </span>
        </div>

        <el-row v-if="multipleSelection.length <= 0" justify="end">
          <el-col :span="6">
            <el-form-item
              :label="$t('DistributerProfilePageAreaLabel')"
              :label-width="formLabelWidth"
              prop="requiredField"
            >
              <el-select
                v-if="lookups && lookups.areas"
                clearable
                class="u-right-margin--2x u-flex-shrink--0"
                v-model="tempAreaPriceObject.area"
                filterable
                multiple
                :placeholder="$t('DistributerProfilePageSelectAreaPlaceholder')"
              >
                <el-option
                  v-for="(area, i) in lookups.areas"
                  :key="i"
                  :label="area.value"
                  :value="area.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :label="$t('DistributerProfilePageMinimumOrderPriceLabel')"
              :label-width="formLabelWidth"
              :class="
                $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
              "
              prop="requiredField"
            >
              <el-input
                type="number"
                :placeholder="
                  $t('DistributerProfilePageInputMinimumOrderPricePlaceholder')
                "
                v-model="tempAreaPriceObject.minOrderAmount"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :label="$t('DistributerProfilePageMinimumItemsPerOrderLabel')"
              :label-width="`180px`"
              :class="
                $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
              "
              prop="requiredField"
            >
              <el-input
                type="number"
                :placeholder="
                  $t('DistributerProfilePageInputMinimumOrderItemPlaceholder')
                "
                v-model="tempAreaPriceObject.minNumberItems"
                autocomplete="off"
              />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              :label="$t('DistributerProfilePageLabelIsActive')"
              :label-width="formLabelWidth"
            >
              <el-switch v-model="tempAreaPriceObject.isActive"> </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              :label="$t('DistributerProfilePageRateLabel')"
              :label-width="formLabelWidth"
              :class="
                $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
              "
            >
              <el-rate allow-half v-model="tempAreaPriceObject.rating">
              </el-rate>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :label="$t('DistributerProfilePageLabelPaymentMethod')"
              :label-width="formLabelWidth"
              prop="requiredField"
            >
              <el-select
                clearable
                class="u-right-margin--2x u-flex-shrink--0 fullWidth"
                v-model="tempAreaPriceObject.paymentMethod"
                filterable
                :placeholder="$t('DistributerProfilePagePlaceholderPayMethod')"
              >
                <el-option
                  v-for="(singleMethod, i) in paymentMethods"
                  :key="i"
                  :label="singleMethod.label"
                  :value="singleMethod.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              :label="$t('DistributerProfilePageLabelSections')"
              :label-width="formLabelWidth"
              prop="requiredField"
            >
              <el-select
                v-if="lookups && lookups.sections"
                clearable
                class="u-right-margin--2x u-flex-shrink--0"
                v-model="tempAreaPriceObject.sections"
                filterable
                multiple
                :placeholder="$t('DistributerProfilePagePlaceholderSections')"
              >
                <el-option
                  v-for="(section, i) in lookups.sections"
                  :key="i"
                  :label="section.key"
                  :value="section.key"
                >
                  <span style="float: left">{{ section.key }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    section.title
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button
              :class="
                $store.state.isRTL ? 'u-left-margin--2x' : 'u-right-margin--2x'
              "
              style="float: right"
              v-if="tempAreaPriceObject.area || multipleSelection.length > 0"
              :disabled="!tempAreaPriceObject.area"
              type="primary"
              @click="newAssignAreaToProvider"
            >
              {{ $t("DistributerProfilePageAddBtn") }}
            </el-button>
          </el-col>
        </el-row> -->

        <!--  -------------------------------------------------- -->
        <!--  Multiple Rows Selected for update Area -->
        <!--  -------------------------------------------------- -->
        <el-row v-if="multipleSelection.length > 0" type="flex-row">
          <el-col :span="5">
            <el-form-item :label="$t('DistributerProfilePageMinimumOrderPriceLabel')" :label-width="formLabelWidth">
              <el-input type="number" :placeholder="$t('DistributerProfilePageInputMinimumOrderPricePlaceholder')
      " v-model="sectionsUpdatesObject.minOrderAmount" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="1">
            <el-form-item :label="$t('DistributerProfilePageMinimumItemsPerOrderLabel')" :label-width="formLabelWidth">
              <el-input type="number" :placeholder="$t('DistributerProfilePageInputMinimumOrderItemPlaceholder')
      " v-model="sectionsUpdatesObject.minNumberItems" autocomplete="off" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item :label="$t('DistributerProfilePageRateLabel')" :label-width="`100px`">
              <el-rate allow-half v-model="sectionsUpdatesObject.rating" />
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item :label="$t('DistributerProfilePageLabelIsActive')" :label-width="`100px`">
              <el-switch allow-half v-model="sectionsUpdatesObject.isActive" />
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item :label="$t('DistributerProfilePageLabelPaymentMethod')" :label-width="formLabelWidth">
              <el-select class="u-flex-shrink--0" v-model="sectionsUpdatesObject.paymentMethod" filterable
                :placeholder="$t('DistributerProfilePagePlaceholderPayMethod')">
                <el-option v-for="(singleMethod, i) in paymentMethods" :key="i" :label="singleMethod.label"
                  :value="singleMethod.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button class="u-right-margin--2x" style="float: right" multipleSelection.length type="primary"
              @click="newMassEditAreas">
              {{ $t("DistributerProfilePageMassEditBtn") }}
            </el-button>
          </el-col>
        </el-row>
        <div class="u-bottom-margin--1x u-top-margin--2x u-text--right" :class="{
      animate: isAreasUpdated,
    }">
          <!-- <el-button
            type="primary"
            :disabled="!isAreasUpdated"
            @click="updateProviderAreas()"
          >
            Update Provider Areas
          </el-button> -->
        </div>
        <!--  -------------------------------------------------- -->
        <!--  Area Sections Table -->
        <!--  -------------------------------------------------- -->
        <!-- <div v-if="providerAreas.length">
          <el-table
            ref="areaTable"
            :data="providerAreas"
            style="width: 100%"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              :label="$t('DistributerProfilePageAreaNameColumnTitle')"
            >
              <template slot-scope="scope">
                {{ getAreaNameById(scope.row.areaId) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="minOrderAmount"
              :label="$t('DistributerProfilePageMinimumOrderAmountColumnTitle')"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.editable">
                  {{ scope.row.minOrderAmount }}
                </span>
                <el-input
                  type="number"
                  v-else
                  :controls="false"
                  v-model="scope.row.minOrderAmount"
                  @change="isAreasUpdated = true"
                  :min="1"
                  label=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="minNumberItems"
              :label="
                $t('DistributerProfilePageMinimumItemsPerOrderColumnTitle')
              "
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.editable">
                  {{ scope.row.minNumberItems }}
                </span>
                <el-input
                  type="number"
                  v-else
                  :controls="false"
                  v-model="scope.row.minNumberItems"
                  @change="isAreasUpdated = true"
                  :min="1"
                  label=""
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="rating"
              :label="$t('DistributerProfilePageRatingColumnTitle')"
            >
              <template slot-scope="scope">
                <div>
                  <el-rate
                    allow-half
                    @change="isAreasUpdated = true"
                    :disabled="!scope.row.editable"
                    v-model="scope.row.rating"
                  >
                  </el-rate>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Section">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.sectionKey ? scope.row.sectionKey : "null" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="isActive" label="isActive">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isActive"
                  :disabled="!scope.row.editable"
                  :active-value="1"
                  :inactive-value="0"
                />
              </template>
            </el-table-column>
            <el-table-column prop="paymentMethod" label="Payment Method">
              <template slot-scope="scope">
                <span v-if="!scope.row.editable">
                  {{ scope.row.paymentMethod }}
                </span>
                <el-select
                  class="u-flex-shrink--0"
                  v-model="scope.row.paymentMethod"
                  filterable
                  v-else
                  placeholder="Please select payment method"
                >
                  <el-option
                    v-for="(singleMethod, i) in paymentMethods"
                    :key="i"
                    :label="singleMethod.label"
                    :value="singleMethod.value"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  type="success"
                  :icon="scope.row.editable ? 'el-icon-check' : 'el-icon-edit'"
                  title="Edit"
                  plain
                  @click="EditRow(scope)"
                >
                </el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  plain
                  @click="removeAreaFromProvider(scope.row)"
                >
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div> -->

        <!-- <div class="u-top-margin--1x u-text--right">
          <el-button
            type="primary"
            :disabled="!isAreasUpdated"
            @click="updateProviderAreas()"
          >
            Update Provider Areas
          </el-button>
        </div> -->
      </el-form>
    </div>
    <!--  -------------------------------------------------- -->
    <!-- Black-Seaction -->
    <!--  -------------------------------------------------- -->
    <div v-if="labelPosition == 'BlackList'">
      <el-form :model="addBlackList">
        <el-form-item label="User Id" :label-width="formLabelWidth">
          <div class="u-display-flex">
            <el-input class="u-right-margin--2x" placeholder="Enter User Id " v-model="addBlackList.userId"
              autocomplete="off" />
            <el-input class="u-right-margin--2x" v-model="addBlackList.note" placeholder="Enter Note"
              v-if="addBlackList.userId" autocomplete="off" />
            <el-button class="u-right-margin--2x" v-if="addBlackList.userId"
              :disabled="!addBlackList.userId || !addBlackList.note" type="primary" @click="addUserToBlackList">
              Add
            </el-button>
          </div>
        </el-form-item>

        <div v-if="providerBlockedUsers.length">
          <el-table :data="providerBlockedUsers" style="width: 100%">
            <el-table-column label="User Id">
              <template slot-scope="scope">
                {{ scope.row.id }}
              </template>
            </el-table-column>
            <el-table-column prop="note" label="Note"> </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button type="danger" icon="el-icon-delete" plain @click="unBlockUser(scope.row.id)">
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="u-top-margin--1x u-text--right">
          <el-button type="primary" :disabled="!isblackListUpdated" @click="updateProviderAreas()">
            Update Provider Black list
          </el-button>
        </div>
      </el-form>
    </div>
    <!--  -------------------------------------------------- -->
    <!-- Wallet-Seaction -->
    <!--  -------------------------------------------------- -->
    <div v-if="labelPosition == 'Wallet'">
      <el-card :body-style="{ padding: '20px', fontSize: '25px' }" style="width: 40%">
        <div slot="header">
          <span>User Wallet</span>
        </div>
        <div>Wallet : xxxxxx-xxxxxx-xxxxxx-xxxxxx</div>
        <div>Balance : 15,00000</div>
      </el-card>
    </div>
    <ProviderWorkingHoursHandingDialog v-model="showWorkingHoursDialog" />
  </div>
</template>

<script>
import _ from "lodash";
import { providersService } from "../services/providers.service";
import { mapGetters } from "vuex";
import ProviderProfileTabs from "./ProviderScreens/ProviderProfile/ProviderProfileTabs.vue";
import ProviderWorkingHoursHandingDialog from "./ProviderScreens/ProviderProfile/ProviderWorkingHoursHandingDialog.vue";
import { generateValidationRules } from "./DistributersPage.vue";

export default {
  name: "DistributerProfile",
  components: {
    ProviderProfileTabs,
    ProviderWorkingHoursHandingDialog,
  },
  data() {
    return {
      labelPosition: "Areas",
      providersTypes: [
        { label: "Suppliers", value: "supplier", tooltip: "الشركات" },
        { label: "Distributers", value: "distributer", tooltip: "تجار الجمله" },
      ],
      profileSections: ["Areas", "BlackList", "Wallet"],
      multipleSelection: [],
      formLabelWidth: "160px",
      isAreasUpdated: false,
      isblackListUpdated: false,
      providerProfile: { oneLineDescription: null },
      EditMode: false,
      addBlackList: {},
      editDistributerRules: {
        nationalName: generateValidationRules(250, "National Name"),
        taxName: generateValidationRules(250, "Tax Name"),
        nationalId: [
          {
            required: false,
            message: this.$t("National ID is required"),
            trigger: "change",
          },
          {
            pattern: /^\d{14}$/, // Regular expression for 14 digits
            message: "National ID must be a 14-digit number",
            trigger: "change",
          },
        ],
        taxId: [
          {
            required: false,
            message: this.$t("Tax ID is required"),
            trigger: "change",
          },
          {
            pattern: /^\d{9}$/, // Regular expression for 14 digits
            message: "Tax ID must be a 9-digit number",
            trigger: "change",
          },
        ],
        // requiredField: [
        //   {
        //     required: true,
        //   },
        // ],
        endDate: [
          {
            required: true,
            message: "Please input end date",
            trigger: "blur",
          },
        ],
      },
      tempAreaPriceObject: {
        minNumberItems: 1,
        isActive: true,
        // fatura: false,
        // credit: false,
        // direct: true,
        // faturaCredit: false,
      },
      providerAreas: {},
      providerBlockedUsers: [],
      paymentMethods: [
        { label: "Cash", value: "cash" },
        { label: "Both", value: "both" },
      ],
      sectionsUpdatesObject: {
        isActive: false,
      },
      profileImageUrl: "",
      imageFileList: [],
      showWorkingHoursDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
      storedProviderProfile: "getCurrentProviderProfile",
    }),
    distributerId() {
      return this.$route.params.id;
    },
    providesActivityTypeArray() {
      let currentLookups =
        this.lookups && this.lookups.entity_types
          ? this.lookups.entity_types.filter(
            (singleType) =>
              singleType.activity && singleType.activity == "provides"
          )
          : [];
      return currentLookups;
    },
  },
  mounted() {
    this.$loading();

    this.getProfile().then(this.getProducts);
  },
  methods: {
    limitInputToMaxDigits(maxDigits, inputName) {
      this.providerProfile[inputName] = this.providerProfile[inputName].replace(
        /\D/g,
        ""
      );
      if (this.providerProfile[inputName].length > maxDigits) {
        this.providerProfile[inputName] = this.providerProfile[inputName].slice(
          0,
          maxDigits
        );
      }
    },
    typeIdChanged() {
      // Reset validation rules based on selected typeId
      if (this.providerProfile.typeId === "taxId") {
        this.$refs["editDistributerForm"].clearValidate("nationalName");
        this.$refs["editDistributerForm"].clearValidate("nationalId");
      } else if (this.providerProfile.typeId === "nationalId") {
        this.$refs["editDistributerForm"].clearValidate("taxName");
        this.$refs["editDistributerForm"].clearValidate("taxId");
      }
    },
    cancelEdit() {
      this.EditMode = false;

      this.getProfile();
    },

    async EditDistributer() {
      try {
        let toBeSentFormData = new FormData();
        let profileUpdateObject = {
          name: this.providerProfile.name,
          providerType: this.providerProfile.providerType,
          active: this.providerProfile.active,
          meta: this.providerProfile.meta,
          productsCategory: this.providerProfile.productsCategory,
          landline: this.providerProfile.user.landline,
          address: this.providerProfile.user.address,
          notes: this.providerProfile.user.notes,
          oneLineDescription: this.providerProfile.oneLineDescription,
          is_offline: this.providerProfile.is_offline,

          nationalId:
            this.providerProfile.typeId === "nationalId"
              ? this.providerProfile.nationalId
              : null,
          nationalName:
            this.providerProfile.typeId === "nationalId"
              ? this.providerProfile.nationalName
              : null,
          taxId:
            this.providerProfile.typeId === "taxId"
              ? this.providerProfile.taxId
              : null,
          taxName:
            this.providerProfile.typeId === "taxId"
              ? this.providerProfile.taxName
              : null,
        };
        if (this.providerProfile?.is_offline) {
          profileUpdateObject = {
            ...profileUpdateObject,
            startDate: this.providerProfile.startDate,
            endDate: this.providerProfile.endDate,
          }
        }
        if (this.providerProfile.is_offline && !this.providerProfile.endDate) {
          this.popupMessageWrapper("end date is required", "error", 3000);
          return;
        }
        if (this.imageFileList.length > 0) {
          toBeSentFormData.append("image", this.imageFileList[0].raw);
          toBeSentFormData.append("ids", JSON.stringify([this.distributerId]));
        }
        let dataObject = {
          providerId: this.distributerId,
          data: profileUpdateObject,
        };
        const storedProviderProfile = this.storedProviderProfile;
        let updateSectionStatusResponse = true;
        const profileUpdateResponse =
          await providersService.updateProviderProfile(dataObject);
        if (
          this.providerProfile.is_offline != storedProviderProfile.is_offline
        ) {
          updateSectionStatusResponse = this.updateSectionStatuses();
        }

        let responses = await Promise.all([
          updateSectionStatusResponse,
          profileUpdateResponse,
        ]);
        if (this.imageFileList.length > 0) {
          let imageUploadResponse = await providersService.updateProviderImage(
            toBeSentFormData
          );
          if (imageUploadResponse && !profileUpdateResponse) {
            this.popupMessageWrapper(
              this.$t("DistributerProfilePageUpdateProfileErrorMessage"),
              "error",
              3000
            );
          }
        }
        if (responses) {
          this.popupMessageWrapper(
            this.$t("DistributerProfilePageUpdateProfileSuccessMessage"),
            "success",
            3000
          );
        }
        this.EditMode = false;
        this.imageFileList = [];
        this.getProfile();
      } catch (err) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(err);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.resetSectionsUpdateObject();
    },
    goBack() {
      this.$router.go(-1);
    },
    getTypeId(data) {
      const hasNationalId = !!data?.nationalId;
      const hasTaxId = !!data?.taxId;

      if (hasNationalId && !hasTaxId) {
        return "nationalId";
      } else if (!hasNationalId && hasTaxId) {
        return "taxId";
      } else {
        return null;
      }
    },
    async getProfile() {
      this.$loading();
      try {
        let providerProfileData = await providersService.getProviderProfile(
          this.distributerId
        );

        if (!providerProfileData.meta) {
          providerProfileData.meta = {};
        }

        if (providerProfileData.meta) {
          const currentMeta = providerProfileData.meta;
          let currentProfileImage = currentMeta.logo
            ? currentMeta.logo
            : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
          this.profileImageUrl = currentProfileImage;
        }
        this.providerAreas = providerProfileData.areas.map((item) => {
          return { ...item, editable: false };
        });

        if (providerProfileData?.is_offline) {
          this.providerProfile = {
            ...providerProfileData,
            typeId: this.getTypeId(providerProfileData),
            startDate: providerProfileData?.startDateHold,
            endDate: providerProfileData?.endDateHold,
          };
        }else{
          this.providerProfile = {
            ...providerProfileData,
            typeId: this.getTypeId(providerProfileData),
          
          };
        }
        this.$store.commit("setCurrentProviderProfile", providerProfileData);
        this.$loading().close();
        return providerProfileData;
      } catch (error) {
        console.log("error :>> ", error);
        this.$loading().close();
      }
    },

    getAreaNameById(areaId) {
      if (!this.lookups) return;
      let area = _.find(this.lookups.areas, { key: areaId });
      return area && area.value ? area.value : "null";
    },
    addUserToBlackList() {
      if (!this.isblackListUpdated) this.isblackListUpdated = true;
      let isUserAlreadyAdded = _.find(this.providerBlockedUsers, {
        id: this.addBlackList.userId,
      });
      if (isUserAlreadyAdded) {
        isUserAlreadyAdded.note = this.addBlackList.note;
      } else {
        this.providerBlockedUsers.push({
          id: this.addBlackList.userId,
          note: this.addBlackList.note,
        });
      }
    },
    async EditRow(scope) {
      scope.row.editable = !scope.row.editable;
      if (!scope.row.editable) {
        const chosenRow = JSON.parse(JSON.stringify(scope.row));
        let toBeSentData = [
          {
            areaId: chosenRow.areaId,
            sectionKey: chosenRow.sectionKey,
            minOrderAmount: chosenRow.minOrderAmount,
            minNumberItems: chosenRow.minNumberItems,
            rating: chosenRow.rating,
            isActive: chosenRow.isActive,
            paymentMethod: chosenRow.paymentMethod,
          },
        ];
        const providerId = this.providerProfile.id;
        this.$loading();
        try {
          const response = await providersService.newAssignProvidersToSections(
            providerId,
            toBeSentData
          );
          if (response) {
            this.$loading().close();
            await this.getProfile();
          }
        } catch (error) {
          console.log("error", error);
          this.$loading().close();
        }
      }
    },
    massEditAreas() {
      this.$confirm(
        `${this.$t("DistributerProfilePageMassEditConfirmBoxTitle")}
         <br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxMinimumOrderPriceLabel"
        )} </strong> ${this.tempAreaPriceObject.price}
         <br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxMinimumItemsPerOrderLabel"
        )} </strong> ${this.tempAreaPriceObject.minNumberItems}
         <br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxDirectLabel"
        )}</strong> ${this.tempAreaPriceObject.direct}
         <br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxFaturaLabel"
        )}</strong> ${this.tempAreaPriceObject.fatura}
         <br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxFaturaCreditLabel"
        )}</strong> ${this.tempAreaPriceObject.faturaCredit}
         <br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxCreditLabel"
        )}</strong> ${this.tempAreaPriceObject.credit}
         `,
        "Warning",
        {
          confirmButtonText: "OK",
          dangerouslyUseHTMLString: true,
          cancelButtonText: "Cancel",
        }
      )
        .then(() => {
          this.isAreasUpdated = true;
          let isAreaAlreadyAdded = this.providerAreas.filter((item) => {
            return this.multipleSelection
              .map((item) => item.id)
              .includes(item.id);
          });
          for (let i = 0; i < isAreaAlreadyAdded.length; i++) {
            isAreaAlreadyAdded[i].minOrderAmount =
              this.tempAreaPriceObject.price;
            isAreaAlreadyAdded[i].minNumberItems =
              this.tempAreaPriceObject.minNumberItems;
            isAreaAlreadyAdded[i].fatura = this.tempAreaPriceObject.fatura;
            isAreaAlreadyAdded[i].credit = this.tempAreaPriceObject.credit;
            isAreaAlreadyAdded[i].faturaCredit =
              this.tempAreaPriceObject.faturaCredit;
            isAreaAlreadyAdded[i].direct = this.tempAreaPriceObject.direct;
          }
          this.multipleSelection = [];
          this.$refs.areaTable.clearSelection();
          this.tempAreaPriceObject = { direct: true, area: [] };
          this.updateProviderAreas();
        })
        .catch(() => { });
    },
    assignAreaToProvider() {
      if (!this.isAreasUpdated) this.isAreasUpdated = true;
      let isAreaAlreadyAdded = this.providerAreas.filter((item) => {
        return this.tempAreaPriceObject.area.includes(item.id);
      });

      if (isAreaAlreadyAdded.length > 0) {
        for (let i = 0; i < isAreaAlreadyAdded.length; i++) {
          isAreaAlreadyAdded[i].minOrderAmount = this.tempAreaPriceObject.price;
          isAreaAlreadyAdded[i].minNumberItems =
            this.tempAreaPriceObject.minNumberItems;
          isAreaAlreadyAdded[i].fatura = this.tempAreaPriceObject.fatura;
          isAreaAlreadyAdded[i].credit = this.tempAreaPriceObject.credit;
          isAreaAlreadyAdded[i].faturaCredit =
            this.tempAreaPriceObject.faturaCredit;
          isAreaAlreadyAdded[i].direct = this.tempAreaPriceObject.direct;
          isAreaAlreadyAdded[i].rating = this.tempAreaPriceObject.rating;
        }
      }
      if (
        isAreaAlreadyAdded.length <= 0 ||
        isAreaAlreadyAdded.length < this.tempAreaPriceObject.area.length
      ) {
        for (let i = 0; i < this.tempAreaPriceObject.area.length; i++) {
          if (
            !isAreaAlreadyAdded
              .map((item) => item.id)
              .includes(this.tempAreaPriceObject.area[i])
          ) {
            this.providerAreas.push({
              id: this.tempAreaPriceObject.area[i],
              minOrderAmount: this.tempAreaPriceObject.price,
              minNumberItems: this.tempAreaPriceObject.minNumberItems,
              fatura: this.tempAreaPriceObject.fatura,
              credit: this.tempAreaPriceObject.credit,
              faturaCredit: this.tempAreaPriceObject.faturaCredit,
              direct: this.tempAreaPriceObject.direct,
              rating: this.tempAreaPriceObject.rating,
              editable: false,
            });
          }
        }
      }
      this.tempAreaPriceObject = { direct: true };
      this.updateProviderAreas();
    },
    unBlockUser(selectedUserId) {
      if (!this.isblackListUpdated) this.isblackListUpdated = true;
      this.providerBlockedUsers = _.reject(this.providerBlockedUsers, {
        id: selectedUserId,
      });
    },
    async removeAreaFromProvider(clickedSection) {
      this.$confirm(
        `${this.$t("DistributerProfilePageDeleteAreaWarningBoxMessage")}`,
        `${this.$t("DistributerProfilePageDeleteAreaWarningBoxTitle")}`,
        {
          confirmButtonText: this.$t(
            "DistributerProfilePageDeleteAreaWarningBoxOkBtn"
          ),
          cancelButtonText: this.$t(
            "DistributerProfilePageDeleteAreaWarningBoxCancelBtn"
          ),
        }
      )
        .then(async () => {
          const entityId = this.providerProfile.id;
          const areaId = clickedSection.areaId;
          const sectionKey = clickedSection.sectionKey;
          try {
            this.$loading();
            const response = await providersService.deleteProviderSection(
              entityId,
              areaId,
              sectionKey
            );
            if (response) {
              this.$loading().close();
              await this.getProfile();
            }
          } catch (error) {
            this.$loading().close();
            this.$notify({
              title: "Error Occured",
              message: error.response.data.message,
              type: "error",
            });
          }
        })
        .catch(() => { });
    },

    updateProviderAreas() {
      this.$loading();
      const providerId = this.providerProfile.id;
      const areas = this.providerAreas.map((area) => {
        return {
          id: area.id,
          amount: area.minOrderAmount,
          fatura: area.fatura ? 1 : 0,
          direct: area.direct ? 1 : 0,
          faturaCredit: area.faturaCredit ? 1 : 0,
          credit: area.credit ? 1 : 0,
          rating: area.rating,
          numberOfItems: area.minNumberItems ? area.minNumberItems : 1,
        };
      });
      const filters = {
        areas,
      };
      providersService
        .updateProviderAreas({ providerId, filters })
        .then((res) => {
          this.providerProfile.areas = res.provider.areas;
        })
        .finally(() => {
          this.$loading().close();
          this.isAreasUpdated = false;
        });
    },
    async newAssignAreaToProvider() {
      const dataIsValid = this.validateCreateData();
      if (dataIsValid == false) return;
      const newInsertionsData = JSON.parse(
        JSON.stringify(this.tempAreaPriceObject)
      );
      const chosenAreas = newInsertionsData.area;
      const chosenSections = newInsertionsData.sections;
      const commonData = {
        isActive: newInsertionsData.isActive,
        minNumberItems: newInsertionsData.minNumberItems,
        paymentMethod: newInsertionsData.paymentMethod,
        price: newInsertionsData.price,
        rating: newInsertionsData.rating,
        minOrderAmount: newInsertionsData.minOrderAmount,
      };
      let toBeSentData = [];
      chosenAreas.forEach((singleArea) => {
        chosenSections.forEach((singleSection) => {
          let tempObj = {
            ...commonData,
            areaId: singleArea,
            sectionKey: singleSection,
          };
          toBeSentData.push(JSON.parse(JSON.stringify(tempObj)));
        });
      });
      const providerId = this.providerProfile.id;
      this.$loading();
      try {
        const response = await providersService.newAssignProvidersToSections(
          providerId,
          toBeSentData
        );
        if (response) {
          this.$loading().close();
          this.tempAreaPriceObject = {
            minNumberItems: 1,
            isActive: true,
            area: [],
          };
          this.getProfile();
        }
      } catch (error) {
        console.log("error", error);
        this.$loading().close();
      }
    },
    async newMassEditAreas() {
      const constructedData = this.constructUpdateData();
      this.$confirm(constructedData.message, "Warning", {
        confirmButtonText: this.$t("GlobalOkBtn"),
        dangerouslyUseHTMLString: true,
        cancelButtonText: this.$t("GlobalCancelBtn"),
      })
        .then(async () => {
          const commonData = constructedData.dataToBeSent;
          let toBeSentData = [];
          this.multipleSelection.forEach((singleSelection) => {
            let tempObj = {
              ...commonData,
              areaId: singleSelection.areaId,
              sectionKey: singleSelection.sectionKey,
            };
            toBeSentData.push(JSON.parse(JSON.stringify(tempObj)));
          });
          const providerId = this.providerProfile.id;
          this.$loading();
          try {
            const response =
              await providersService.newAssignProvidersToSections(
                providerId,
                toBeSentData
              );
            if (response) {
              this.$loading().close();
              this.resetSectionsUpdateObject();
              await this.getProfile();
            }
          } catch (error) {
            console.log("error", error);
            this.$loading().close();
          }
        })
        .catch(() => { });
    },
    constructUpdateData() {
      let message = `${this.$t(
        "DistributerProfilePageMassEditConfirmBoxTitle"
      )}`;
      let dataToBeSent = {};
      const updateObj = JSON.parse(JSON.stringify(this.sectionsUpdatesObject));
      if (updateObj.minOrderAmount) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxMinimumOrderPriceLabel"
        )} </strong> ${this.sectionsUpdatesObject.minOrderAmount}`;
        dataToBeSent.minOrderAmount = updateObj.minOrderAmount;
      }
      if (updateObj.minNumberItems) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxMinimumItemsPerOrderLabel"
        )} </strong> ${this.sectionsUpdatesObject.minNumberItems}`;
        dataToBeSent.minNumberItems = updateObj.minNumberItems;
      }
      if (updateObj.rating && updateObj.rating > 0) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxLabelRate"
        )}</strong> ${this.sectionsUpdatesObject.rating}`;
        dataToBeSent.rating = updateObj.rating;
      }
      if ("isActive" in updateObj) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxLabelIsActive"
        )}</strong> ${this.sectionsUpdatesObject.isActive == 1 ? "true" : "false"
          }`;
        dataToBeSent.isActive = updateObj.isActive;
      }
      if (updateObj.paymentMethod) {
        message += `<br /> <strong> ${this.$t(
          "DistributerProfilePageMassEditConfirmBoxLabelPayMethod"
        )}</strong> ${this.sectionsUpdatesObject.paymentMethod}`;
        dataToBeSent.paymentMethod = updateObj.paymentMethod;
      }
      return { message, dataToBeSent };
    },
    resetSectionsUpdateObject() {
      this.sectionsUpdatesObject = {
        isActive: false,
      };
    },
    validateCreateData() {
      try {
        const newInsertionsData = JSON.parse(
          JSON.stringify(this.tempAreaPriceObject)
        );
        const chosenAreas = newInsertionsData.area;
        const chosenSections = newInsertionsData.sections;

        let returnedFlag = true;
        let errorMessage = "";

        if (chosenSections.length == 0) {
          errorMessage = this.$t(
            "DistributerProfilePageValidationErrorMessageSections"
          );
          returnedFlag = false;
        }
        if (!("paymentMethod" in newInsertionsData)) {
          errorMessage = this.$t(
            "DistributerProfilePageValidationErrorMessagePayMethod"
          );
          returnedFlag = false;
        }
        if (!("minOrderAmount" in newInsertionsData)) {
          errorMessage = this.$t(
            "DistributerProfilePageValidationErrorMessageMinOrderAmount"
          );
          returnedFlag = false;
        }
        if (chosenAreas.length == 0) {
          errorMessage = this.$t(
            "DistributerProfilePageValidationErrorMessageAreas"
          );
          returnedFlag = false;
        }

        if (!returnedFlag)
          this.popupMessageWrapper(errorMessage, "error", 2000);

        return returnedFlag;
      } catch (error) {
        console.log("error :>> ", error);
        this.popupMessageWrapper(
          this.$t("DistributerProfilePageValidationErrorMessageDefaultError"),
          "error",
          2000
        );
        return false;
      }
    },
    popupMessageWrapper(message = "", type = "success", duration = 1000) {
      this.$message({ message, type, duration });
    },
    handleChangeImage(changedData) {
      this.imageFileList = [changedData];
      this.profileImageUrl = changedData.url;
    },
    handleRemoveImage() {
      this.imageFileList = [];
      const currentMeta = this.providerProfile.meta;
      let currentProfileImage = currentMeta.logo
        ? currentMeta.logo
        : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png";
      this.profileImageUrl = currentProfileImage;
    },
    offlineToggleChanged(value) {
      const storedProviderProfile = this.storedProviderProfile;

      if (this.providerProfile.is_offline != storedProviderProfile.is_offline) {
        if (value == 1) {
          this.$confirm(
            "This will turn the provider to be offline. Continue?",
            "Warning",
            {
              confirmButtonText: this.$t("GlobalOkBtn"),
              cancelButtonText: this.$t("GlobalCancelBtn"),
            }
          )
            .then(async () => {
              if (!this.providerProfile?.startDate) {
                this.providerProfile = {
                  ...this.providerProfile,
                  startDate: new Date(),
                };
              }
              console.log("ok clicked");
            })
            .catch(() => {
              this.providerProfile.is_offline = 0;
            });
        }
        if (value == 0) {
          this.$confirm(
            "This will turn the provider to be online . Continue?",
            "Warning",
            {
              confirmButtonText: this.$t("GlobalOkBtn"),
              cancelButtonText: this.$t("GlobalCancelBtn"),
            }
          )
            .then(async () => {
              console.log("ok clicked");
            })
            .catch(() => {
              this.providerProfile.is_offline = 1;
            });
        }
      }
    },
    updateSectionStatuses() {
      const isActiveValue = this.providerProfile.is_offline == 1 ? false : true;

      let toBeSentData = this.providerProfile.areas.map((item) => {
        return {
          areaId: item.areaId,
          sectionKey: item.sectionKey,
          isActive: isActiveValue,
        };
      });

      const providerId = this.providerProfile.id;
      if (this.providerProfile.is_offline === 1) {
        return providersService.newAssignProvidersToSections(
          providerId,
          toBeSentData
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-radio-group {
  display: flex !important;
  margin: 20px;
  justify-content: center;
}

@keyframes shakeAnimation {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.animate {
  animation: shakeAnimation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

[dir="rtl"] .el-form-item ::v-deep .el-form-item__content {
  margin-left: 0 !important;
  margin-right: 175px !important;
}

.fullWidth {
  width: 100%;
}

.distributer-profile {
  &__image-holder {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 150px;
    margin-bottom: 10px;
  }

  &__edit-image-button-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__top-buttons-holder {
    display: flex;
    justify-content: flex-end;
  }
}

.danger-zone {
  padding-top: 10px;
  width: 100%;
  border: 1px solid #f3c1c1;
  border-radius: 5px;
}
</style>
