<template>
  <div>
    <el-dialog
      :title="$t('ProviderWorkingHoursDialogLabel')"
      :visible.sync="localVisible"
    >
      <div>
        <div class="u-bottom-margin--2x">
          <div class="u-bottom-margin--2x">
            {{ $t("ProviderWorkingHoursDialogDefaultLabel") }}
          </div>
          <div>
            <el-time-picker
              :disabled="!showTimePicker"
              v-model="timeIntervalFrom"
              arrow-control
              value-format="H"
              :picker-options="{
                format: 'H A',
              }"
              class="u-pillar-margin--2x"
              style="width: auto"
            />
            <span class="u-pillar-margin--2x">{{
              $t("ProviderWorkingHoursDialogToLabel")
            }}</span>
            <el-time-picker
              :disabled="!showTimePicker"
              v-model="timeIntervalTo"
              arrow-control
              value-format="H"
              :picker-options="{
                format: 'H A',
              }"
              class="u-pillar-margin--2x"
              style="width: auto"
            />
            <!-- <el-time-picker
            class="u-pillar-margin--2x"
            is-range
            v-model="timeInterval"
            :range-separator="$t('ProviderWorkingHoursDialogToLabel')"
            arrow-control
            value-format="H"
            :picker-options="{
              format: 'H A',
            }"
            :disabled="!showTimePicker"
          >
          </el-time-picker> -->
            <el-button
              v-if="workingHoursData.length == 0"
              size="mini"
              type="success"
              @click="setDefaultData"
            >
              {{
                $t("ProviderWorkingHoursDialogApplyToAllBtnLabel")
              }}</el-button
            >
          </div>
        </div>
        <div class="u-bottom-margin--2x">
          <el-table :data="workingHoursData" border stripe>
            <el-table-column
              prop="day"
              :label="$t('ProviderWorkingHoursDialogTableColumnDays')"
            />
            <el-table-column
              prop="opening_hour"
              :label="$t('ProviderWorkingHoursDialogTableColumnFrom')"
            >
              <template slot-scope="scope">
                <el-time-picker
                  :disabled="scope.row.is_open === 0"
                  style="width: auto"
                  v-model="scope.row.opening_hour"
                  arrow-control
                  value-format="H"
                  :picker-options="{
                    format: 'H A',
                  }"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="closing_hour"
              :label="$t('ProviderWorkingHoursDialogTableColumnTo')"
            >
              <template slot-scope="scope">
                <el-time-picker
                  :disabled="scope.row.is_open === 0"
                  style="width: auto"
                  v-model="scope.row.closing_hour"
                  arrow-control
                  value-format="H"
                  :picker-options="{
                    format: 'H A',
                  }"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="is_open"
              :label="$t('ProviderWorkingHoursDialogTableColumnAvailability')"
            >
              <template slot-scope="scope">
                <div class="u-text--center">
                  <el-checkbox
                    v-model="scope.row.is_open"
                    :true-label="1"
                    :false-label="0"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div></div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="localVisible = false">{{
          $t("GlobalsCancelBtn")
        }}</el-button>
        <el-button type="success" @click="setWorkingHours">{{
          $t("GlobalsSaveBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { entitiesServices } from "../../../services/entities.service";

export default {
  name: "ProviderWorkingHoursHandingDialog",
  props: ["value"],
  data() {
    return {
      localVisible: null,
      timeInterval: ["10", "18"],
      timeIntervalFrom: "10",
      timeIntervalTo: "18",
      defaultData: [
        {
          day: "sunday",
          opening_hour: "3",
          closing_hour: "22",
          is_open: 1,
        },
        {
          day: "monday",
          opening_hour: "3",
          closing_hour: "22",
          is_open: 1,
        },
        {
          day: "tuesday",
          opening_hour: "3",
          closing_hour: "22",
          is_open: 1,
        },
        {
          day: "wednesday",
          opening_hour: "3",
          closing_hour: "22",
          is_open: 1,
        },
        {
          day: "thursday",
          opening_hour: "3",
          closing_hour: "22",
          is_open: 1,
        },
        {
          day: "friday",
          opening_hour: "3",
          closing_hour: "22",
          is_open: 1,
        },
        {
          day: "saturday",
          opening_hour: "3",
          closing_hour: "22",
          is_open: 1,
        },
      ],
      workingHoursData: [],
      showTimePicker: false,
    };
  },
  computed: {
    distributerId() {
      return this.$route.params.id;
    },
  },
  watch: {
    localVisible(value, oldValue) {
      if (value !== oldValue) {
        this.$emit("input", value);
      }
    },
    value(newVal) {
      this.localVisible = newVal;
      this.showTimePicker = true;
      if (newVal === true) {
        this.workingHoursData = [];
        this.fetchWorkingHours();
      }
    },
  },
  created() {
    this.localVisible = this.value;
  },
  methods: {
    async fetchWorkingHours() {
      try {
        const response = await entitiesServices.getEntityWorkingHours(
          this.distributerId
        );
        // console.log("fetchWorkingHours response", response);
        const hoursResponse = response.data.data.map((item) => {
          return {
            closing_hour: JSON.stringify(item.closing_hour),
            day: item.day,
            is_open: item.is_open,
            opening_hour: JSON.stringify(item.opening_hour),
          };
        });
        this.workingHoursData = hoursResponse;
        if (hoursResponse.length > 0) {
          this.showTimePicker = false;
        }
      } catch (error) {
        console.log("fetchWorkingHoursError", error);
      }
    },
    setDefaultData() {
      let defaultDataArray = this.defaultData.map((item) => {
        return {
          ...item,
          opening_hour: this.timeIntervalFrom,
          closing_hour: this.timeIntervalTo,
        };
      });
      this.workingHoursData = JSON.parse(JSON.stringify(defaultDataArray));
    },
    async setWorkingHours() {
      try {
        // const apiObject = { ...this.workingHoursData[0] };
        const apiObject = JSON.parse(JSON.stringify(this.workingHoursData));
        const response = await entitiesServices.setEntityWorkingHours(
          this.distributerId,
          apiObject
        );
        if (response && response.status == 200) {
          this.$message({
            message: this.$t("ProviderWorkingHoursDialogSuccessMessage"),
            type: "success",
            duration: 2000,
          });
          this.showTimePicker = false;
        }
      } catch (error) {
        console.log("setWorkingHoursError", error);
      }
    },
  },
};
</script>

<style></style>
