<template>
  <el-dialog
    title="Add new Profile"
    width="60%"
    @close="clearProfileDialog"
    :visible.sync="dialogVisible"
  >
    <el-form
      :model="userDetails"
      ref="profileForm"
      :rules="profileRules"
      label-width="140px"
      :inline="false"
      size="normal"
    >
      <el-form-item label="User Name" prop="name">
        <el-input v-model="userDetails.name"></el-input>
      </el-form-item>
      <el-form-item label="Phone Number" prop="phone">
        <el-input v-model="userDetails.phone" type="number"></el-input>
      </el-form-item>
      <el-row :gutter="20" align="center" justify="center">
        <el-col :span="16" :offset="0">
          <el-form-item label="User Password" prop="password">
            <el-input v-model="userDetails.password"></el-input> </el-form-item
        ></el-col>
        <el-col :span="8" :offset="0">
          <el-checkbox
            v-model="userDetails.shouldSendSms"
            :indeterminate="false"
            >Send Password</el-checkbox
          >
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer">
      <el-button @click="dialogVisible = false">Cancel</el-button>
      <el-button type="primary" @click="saveProfile">OK</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "userProfileDialog",
  props: { isDialogVisible: { type: Boolean, default: false } },
  data() {
    const mustBeginWith0 = (rule, value, callback) => {
      if (value[0] !== "0") {
        callback(new Error("Number must start with 0"));
      } else callback();
    };
    return {
      userDetails: {},
      profileRules: {
        name: [
          {
            required: true,
            message: "User name is required",
            trigger: "change",
          },
          {
            min: 2,
            max: 50,
            message: "must input between 2 and 50 characters ",
            trigger: "change",
          },
        ],
        phone: [
          {
            required: true,
            message: "User phone is required",
            trigger: "change",
          },
          {
            min: 11,
            max: 11,
            message: "Number must be 11 digits only ",
            trigger: "change",
          },
          { validator: mustBeginWith0, trigger: "blur" },
        ],
      },
      dialogVisible: false,
    };
  },
  watch: {
    isDialogVisible(newValue) {
      this.dialogVisible = newValue;
    },
  },
  methods: {
    async saveProfile() {
      const valid = await this.$refs.profileForm.validate();
      if (valid) this.$emit("user-saved", this.userDetails);
    },
    clearProfileDialog() {
      this.$emit("dialog-closed");
    },
  },
};
</script>
