<template>
  <div>
    <!-- ************************************************************************************ -->
    <!-- button area -->
    <!-- ************************************************************************************ -->
    <div class="u-display-flex u-justify-content--between">
      <div>
        <el-input
          v-model="blackListedUserId"
          placeholder="Filter by entity Id"
          type="number"
          size="normal"
          clearable
        ></el-input>
      </div>
      <el-button
        class="u-bottom-margin--1x"
        type="primary"
        @click="showDialog = true"
      >
        {{ $t("ProviderBlacklistCompButtonLabelAdd") }}
      </el-button>
    </div>
    <template>
      <el-table
        :data="blackListedUsersFiltered"
        border
        stripe
        @select="setSelectedBlacklistedUser"
        @select-all="setSelectedBlacklistedUser"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column
          width="120px"
          prop="id"
          :label="$t('ProviderBlacklistCompTableColumnLabelEntityId')"
        />
        <el-table-column
          prop="area"
          :label="$t('ProviderBlacklistCompTableColumnLabelAreaName')"
        />
        <el-table-column
          prop="storeName"
          :label="$t('ProviderBlacklistCompTableColumnLabelEntityName')"
        />
        <el-table-column
          min-width="150px"
          prop="address"
          :label="$t('ProviderBlacklistCompTableColumnLabeEntityAddress')"
        />
        <el-table-column
          width="100px"
          :label="$t('ProviderBlacklistCompTableColumnLabelOperations')"
        >
          <template slot-scope="scope">
            <el-button
              icon="el-icon-delete"
              circle
              style="color: red"
              @click="deleteBlacklistEntry(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <ProviderBlacklistHandlingDialog
      v-model="showDialog"
      @blacklistSuccess="blacklistSuccess"
    />
    <transition name="el-fade-in-linear">
      <div
        class="bulk-action-button-holder"
        v-show="selectedBlacklistedRows.length > 0"
      >
        <div class="bulk-action-button-holder__parent">
          <el-button type="danger" size="medium" @click="bulkDeleteWrapper">{{
            $t("ProviderBlacklistCompButtonLabelBulk")
          }}</el-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { providersService } from "../../../services/providers.service";
import ProviderBlacklistHandlingDialog from "./ProviderBlacklistHandlingDialog";
import { entitiesServices } from "../../../services/entities.service";

export default {
  name: "ProviderBlacklistHandling",
  components: {
    ProviderBlacklistHandlingDialog,
  },
  data() {
    return {
      blacklistedUsers: [],
      blackListedUserId: null,
      showDialog: false,
      selectedBlacklistedRows: [],
    };
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    distributerId() {
      return this.$route.params.id;
    },
    blackListedUsersFiltered() {
      if (this.blackListedUserId)
        return this.blacklistedUsers.filter(
          (b) => b.id == this.blackListedUserId
        );
      return this.blacklistedUsers;
    },
  },
  created() {
    this.getBlacklistedUsers();
  },
  methods: {
    async getBlacklistedUsers() {
      try {
        const res = await providersService.fetchBlacklistedUsers(
          this.distributerId
        );
        this.blacklistedUsers = res.data.data;
      } catch (error) {
        console.log("blacklistingFetchError :>> ", error);
      }
    },
    async deleteBlacklistEntry(clickedRow) {
      this.$confirm(
        this.$t("ProviderBlacklistCompDeleteConfirmMessage", [
          clickedRow.storeName,
        ]),
        "Warning",
        {
          confirmButtonText: this.$t(
            "DistributerProfilePageDeleteAreaWarningBoxOkBtn"
          ),
          cancelButtonText: this.$t(
            "DistributerProfilePageDeleteAreaWarningBoxCancelBtn"
          ),
        }
      )
        .then(async () => {
          try {
            const entityId = this.distributerId;
            const blockedEntityId = clickedRow.id;
            const deleteResponse = await this.abstractDeleteBlacklistedUsers(
              entityId,
              [blockedEntityId]
            );
            if (deleteResponse) {
              this.selectedBlacklistedRows = [];
              await this.getBlacklistedUsers();
            }
          } catch (error) {
            console.log("error :>> ", error);
            const errorMessage =
              this.$globalFunctions.errorMessageExtractor(error);
            this.$globalFunctions.popupMessageWrapper(
              errorMessage,
              "error",
              2000
            );
          }
        })
        .catch(() => {});
    },
    async blacklistSuccess() {
      this.showDialog = false;
      await this.getBlacklistedUsers();
    },
    setSelectedBlacklistedUser(data) {
      this.selectedBlacklistedRows = data;
    },
    async bulkDeleteBlacklist() {
      try {
        const entityId = this.distributerId;
        const selectedEntitiesIds = this.selectedBlacklistedRows.map(
          (item) => item.id
        );
        const deleteRes = await this.abstractDeleteBlacklistedUsers(
          entityId,
          selectedEntitiesIds
        );
        if (deleteRes) {
          this.selectedBlacklistedRows = [];
          await this.getBlacklistedUsers();
        }
      } catch (error) {
        this.selectedBlacklistedRows = [];
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
    async abstractDeleteBlacklistedUsers(entityId, blockedIdsArray) {
      try {
        const response = await entitiesServices.deleteBlacklistedEntries(
          entityId,
          { blockedIds: blockedIdsArray }
        );
        if (response) {
          this.$globalFunctions.popupMessageWrapper(
            this.$t("ProviderBlacklistCompAbstractDeleteSuccess"),
            "success",
            2000
          );
          return setTimeout(() => {
            return response;
          }, 2000);
        }
      } catch (error) {
        const errorMessage = this.$globalFunctions.errorMessageExtractor(error);
        this.$globalFunctions.popupMessageWrapper(errorMessage, "error", 2000);
      }
    },
    generateHtmlData(usersArray) {
      let outputString = ` <div><p>${this.$t(
        "ProviderBlacklistCompBulkDeleteConfirmation"
      )}</p><ul>`;
      usersArray.forEach((item) => {
        if (item && item.storeName && item.id) {
          outputString += `<li>
            <span>${item.id}</span>
            <span> : </span>
            <span>${item.storeName}</span>
          </li>`;
        }
      });
      outputString += ` </ul></div> `;
      return outputString;
    },
    async bulkDeleteWrapper() {
      const htmlOutput = this.generateHtmlData(this.selectedBlacklistedRows);
      this.$confirm(htmlOutput, "Warning", {
        confirmButtonText: this.$t("GlobalOkBtn"),
        cancelButtonText: this.$t("GlobalCancelBtn"),
        type: "Warning",
        dangerouslyUseHTMLString: true,
        lockScroll: false,
      })
        .then(() => {
          this.bulkDeleteBlacklist();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.bulk-action-button-holder {
  position: absolute;
  top: 5vh;
  right: 5vw;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 250px;
  height: 150px;
  &__parent {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
[dir="rtl"] {
  .bulk-action-button-holder {
    left: 5vw;
  }
}
</style>
